import * as ApiModels from '@typings/api-models';
import { Providers } from '@typings/common';
import FormResponse from './FormResponse';
import NoFormResponse from './NoFormResponse';

type Props = {
  formRequest: Pick<ApiModels.FormRequest, 'response'>
};

const OtherFormDetails: React.FC<Props> = ({ formRequest }) => {
  if (!formRequest.response) {
    return (
      <NoFormResponse />
    );
  }

  return (
    <FormResponse formResponse={formRequest.response} provider={Providers.Typeform}/>
  );
};

export default OtherFormDetails;
