import { Controller } from 'react-hook-form';
import type { Questionnaire, FieldType } from '@/typings/Questionnaire';
import {
  AddressIntl,
  AddressUS,
  Checkboxes,
  Currency,
  DatePicker,
  Input,
  TextArea,
  PhoneInput,
  Percent,
  RadioButtons,
  Select,
  Name,
} from '@/components/DesignLibrary/Atoms';
import { useFormContext } from '@context/FormContext';
import { getValidationRules } from '@helpers/validation/schema';
import { useEffect, useMemo, useState } from 'react';
import getFieldValue from '@helpers/questionnaires/getFieldValue';
import evaluateExpression from '@helpers/evaluateExpression';

type FormFieldProps = {
  listField: Questionnaire.FieldCommon;
  index: number;
  field: Questionnaire.FieldCommon;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  isPreview?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentsMap: { [key in Exclude<FieldType, 'list'>]: React.FC<any> } = {
  'address-international': AddressIntl,
  'address-us': AddressUS,
  // break: () => null,
  currency: Currency,
  date: DatePicker,
  explanation: ({ label }) => <p className="body-sm">{label}</p>,
  'long-text': TextArea,
  'multi-select': Checkboxes,
  name: Name,
  number: Input,
  numeric: Input,
  percent: Percent,
  'phone-number': PhoneInput,
  selection: Select,
  'short-text': Input,
  'single-select': RadioButtons,
  'ssn': Input,
};

const NonListFormField: React.FC<FormFieldProps> = ({ listField, index, field, control, rules, isPreview = false }) => {
  const [isVisible, setIsVisible] = useState(isPreview);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const { formMethods, defaultValues } = useFormContext();
  const listValues = formMethods.watch(listField.key);

  useEffect(() => {
    if (field.conditionExpression && !isPreview) {
      const variablesWithValues = field.conditionExpression.variables.map(variable => ({
        ...variable,
        value: getFieldValue(
          listValues[index]?.[variable.referenceId],
        ),
      }));
      const result = evaluateExpression({
        expression: field.conditionExpression.expression,
        variables: variablesWithValues.reduce(
          (acc, val) => ({
            ...acc,
            [val.variableName]: val.value,
          }),
          {},
        ),
      });
      setIsVisible(Boolean(result));
    } else {
      setIsVisible(true);
    }
    setIsEvaluated(true);
  }, [field, listValues, formMethods, index, listField.key, isPreview]);

  useEffect(() => {
    if (!isVisible && isEvaluated) {
      formMethods.unregister(field.key);
    }
  }, [isVisible, isEvaluated, field.key, formMethods]);

  const defaultValueOption = useMemo(() => field.options && Array.isArray(field.options)
    ? field.options.find(option => option.isDefault === true)
    : undefined, [field.options]);

  useEffect(() => {
    if (
      (field.type === 'selection' || field.type === 'single-select')
      && defaultValueOption
      && !formMethods.getValues(field.key)
    ) {
      formMethods.setValue(field.key, defaultValueOption.key);
    }
  }, [field.type, field.key, defaultValueOption, formMethods]);

  if (field.type === 'list') return null;

  const Component = componentsMap[field.type];

  if (!Component || !isVisible) return null;

  formMethods.register(field.key, getValidationRules(field));

  return (
    <Controller
      name={field.key}
      control={control}
      defaultValue={(field.type === 'selection' || field.type === 'single-select')
        ? defaultValueOption?.key
        : defaultValues[field.key]}
      shouldUnregister={false}
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <>
          <Component
            optional={!field.isRequired}
            {...field}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            ref={field.type === 'single-select' ? null : ref}
            control={control}
            rules={rules}
            required={field.isRequired}
            error={error?.message}
          />
        </>
      )}
    />
  );
};

export default NonListFormField;
