import { useMemo } from 'react';
import * as ApiModels from '@typings/api-models';
import { Providers } from '@typings/common';
import { TBody, TH, THead, Table } from '@/components/DesignLibrary/Table';
import QuestionAndAnswer, { anvilResponseToFields, typeformResponseToFields } from '@components/FormRequest/QuestionAndAnswer';
import s from './FormRequestDetails.module.scss';

type Props = {
  formResponse: ApiModels.FormRequest['response'];
  provider: Providers;
};

const FormResponse: React.FC<Props> = ({ formResponse, provider }) => {
  const fields = useMemo(() => {
    if (!formResponse) {
      return [];
    }

    if (provider === Providers.Anvil) {
      return anvilResponseToFields(formResponse as ApiModels.FormRequestResponseAnvil);
    }
    if (provider === Providers.Typeform) {
      return typeformResponseToFields(formResponse as ApiModels.FormRequestResponseTypeform);
    }
    return formResponse as ApiModels.FormRequestResponseNoodle;
  }, [formResponse, provider]);

  return (
    <Table className={s.table}>
      <THead>
        <tr>
          <TH label="Field" />
          <TH label="Response" />
        </tr>
      </THead>
      <TBody>
        {fields
          .map(field => (
            <QuestionAndAnswer key={field.id} field={field} />
          ))}
      </TBody>
    </Table>
  );
};

export default FormResponse;
