import classNames from 'classnames';
import AddButton from './AddButton';
import s from './TableRow.module.scss';

type Props = {
  state?: null | 'success' | 'warning' | 'error' | 'muted' | 'note';
} & (
  | {
    rowNumber: number;
    onAdd: (newRowIndex: number) => void;
  }
  | {
    rowNumber?: number;
    onAdd?: never;
  }
);

const TableRow: React.FC<Props> = ({ children, rowNumber, state = null, onAdd }) => {
  const handleAdd = (): void => {
    if (onAdd) {
      onAdd(rowNumber + 1);
    }
  };
  return (
    <tr className={classNames(s.wrapper, state === 'success' && s.success, state === 'warning' && s.warning, state === 'error' && s.error, state === 'muted' && s.muted, state === 'note' && s.note)}>
      {children}
      {onAdd && <AddButton onClick={handleAdd}></AddButton>}
    </tr>
  );
};

export default TableRow;
