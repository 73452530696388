import Percent from '@components/Icons/Percent';
import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponsePercentField;
};

const PercentAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><Percent weight='regular' /><BaseAnswer answer={answer === null ? null : answer.toString()} /></div>
);

export default PercentAnswer;
