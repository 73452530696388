import ts from 'typescript';
import BaseEvaluateExpressionError from './BaseEvaluateExpressionError';

class EvaluateExpressionError extends BaseEvaluateExpressionError {
  kind: ts.SyntaxKind;

  details: string | undefined;

  constructor(msg: string, opts: {
    kind: ts.SyntaxKind;
    details?: string;
  }) {
    super(msg);
    this.kind = opts.kind;
    this.details = opts.details;
  }
}

export default EvaluateExpressionError;
