import type { EvaluateResponse } from '../types';
import BaseEvaluateExpressionError from './BaseEvaluateExpressionError';
import UndefinedVariableError from './UndefinedVariableError';

const mergeErrors = (...errors: EvaluateResponse[]): BaseEvaluateExpressionError | null => {
  let response: BaseEvaluateExpressionError | null = null;

  for (let i = 0; i < errors.length; i += 1) {
    const thisError = errors[i];
    if (response && (response instanceof UndefinedVariableError) && (thisError instanceof UndefinedVariableError)) {
      response.missingVariables.push(...thisError.missingVariables);
    } else if (thisError instanceof BaseEvaluateExpressionError) {
      response = thisError;
    }
  }

  return response;
};

export default mergeErrors;
