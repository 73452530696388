import * as Sentry from '@sentry/nextjs';
import ts, { ScriptTarget } from 'typescript';
import{ DEBUG_EVALUATE_EXPRESSIONS } from '@/configuration/client';
import evaluators from './evaluators';
import { EvaluateResponse, Variables } from './types';
import { BaseEvaluateExpressionError, EvaluateExpressionError } from './errors';

const log = DEBUG_EVALUATE_EXPRESSIONS
  ? console
  : {
    error: () => undefined,
    info: () => undefined,
    log: () => undefined,
  };

const evaluateNode = ({
  node,
  variables,
}: {
  node: ts.Statement;
  variables: Variables;
}): EvaluateResponse => {
  if (ts.isExpressionStatement(node)) {
    return evaluators.expressionStatement({
      evaluators, log, node, variables,
    });
  }

  return new EvaluateExpressionError('Unsupported entry node', { kind: node.kind });
};

// Use https://github.com/wessberg/ts-evaluator for hints about adding evaluators
const evaluateExpression = ({ expression, variables }: {
  expression: string;
  variables: Record<string, unknown>;
}): Exclude<EvaluateResponse, BaseEvaluateExpressionError> => {
  const program = ts.createSourceFile('evaluate-expression.ts', expression, ScriptTarget.ES2022);
  const response = evaluateNode({
    node: program.statements[0],
    variables,
  });

  if (response instanceof Error) {
    console.error(response, 'evaluateExpression error');
    Sentry.captureException(response);
    return undefined;
  }

  return response;
};

export default evaluateExpression;
