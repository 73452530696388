import CurrencyCircleDollar from '@components/Icons/CurrencyCircleDollar';
import * as ApiModels from '@typings/api-models';
import * as format from '@format';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseDollarField;
};

const DollarAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><CurrencyCircleDollar /><BaseAnswer answer={answer === null ? null : format.price.withCurrency(answer, 'USD')} /></div>
);

export default DollarAnswer;
