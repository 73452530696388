import Input from '../Input';
import type { InputProps } from '../Input';

export type PercentProps = Omit<InputProps, 'type' | 'suffix'>;

const Percent: React.FC<PercentProps> = props => (
  <Input type="number" suffix="%" {...props} />
);

export default Percent;
