import React, { useCallback, useState } from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import type { Questionnaire } from '@/typings/Questionnaire';
import PlusCircle from '@/components/Icons/PlusCircle';
import MinusCircle from '@/components/Icons/MinusCircle';
import { Table, TableRow, TBody, TD, TH, THead } from '@/components/DesignLibrary/Table';
import { useFeatureFlag } from '@providers/FeatureFlags';
import Button from '@/components/DesignLibrary/Button';
import SearchInput from '@/components/FormFields/SearchInput';
import * as format from '@format';
import PaginationControls from '@components/DesignLibrary/PaginationControls';
import Buttons from '@/components/Buttons';
import Modal from '@/components/Modal';
import { useToast } from '@/hooks';
import CustomSelect from '@/components/FormFields/CustomSelect';
import PencilSimple from '@/components/Icons/PencilSimple';
import Eye from '@/components/Icons/Eye';
import { useFormContext } from '@/context/FormContext';
import { ToastTypeVariants } from '@/context/ToastContext';
import NonListFormField from '../FormField/NonListFormField';

import s from './List.module.scss';

type ListProps = {
  field: Questionnaire.FieldList;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  isPreview?: boolean;
};

const PAGINATION_OPTIONS = [25, 50, 100].map(value => ({ title: `${value} items per page`, value: String(value) }));

const List: React.FC<ListProps> = ({ field, control, rules, isPreview }) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<25 | 50 | 100>(25);
  const [modalOpen, setModalOpen] = useState<number | undefined>(undefined);
  const addToast = useToast();

  const useEditableInTableView = useFeatureFlag('questionnaire-editable-table-view');
  const { formMethods } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: field.key,
  });

  const initializeSubFields = useCallback(() => {
    const newItem: Record<string, unknown> = {};
    (field.fields ?? []).forEach(subField => {
      newItem[subField.key] = undefined;
    });
    return newItem;
  }, [field.fields]);

  const getDisplayValue = (subField: Questionnaire.Field, value: unknown): string => {
    if (!value) return '—';

    if (subField.type === 'single-select' || subField.type === 'selection') {
      return subField.options.find(o => o.key === value)?.label || '—';
    }

    if (subField.type === 'multi-select') {
      if ((value as string[]).length === 1) {
        return 'true';
      }
      return (value as string[]).map(v => subField.options.find(o => o.key === v)?.label || '—').join(', ');
    }

    if (subField.type === 'address-us' || subField.type === 'address-international') {
      return Object.values(value).filter(Boolean).join(', ') || '—';
    }

    if (subField.type === 'name') {
      const name = value as {
        firstName: string;
        middleName?: string;
        lastName: string;
      };
      return `${name.firstName}${name.middleName ? ` ${name.middleName} ` : ' '}${name.lastName}` || '—';
    }

    if (subField.type === 'currency') {
      return (
        value
        && typeof value === 'object'
        && 'amount' in value
        && typeof value.amount === 'number'
        && 'currency' in value
        && typeof value.currency === 'string'
      )
        ? format.price.withCurrency(value.amount, value.currency)
        : '—';
    }

    if (subField.type === 'date') {
      return value && (value instanceof Date || typeof value === 'string' || typeof value === 'number')
        ? new Date(value).toLocaleDateString('default', {
          day: 'numeric',
          month: 'short',
          timeZone: 'UTC',
          year: 'numeric',
        })
        : '—';
    }

    return String(value);
  };

  const validateListItem = async (): Promise<boolean> => {
    if (modalOpen === undefined) return false;

    const isValid = await formMethods.trigger(`${field.key}[${modalOpen}]`);
    return isValid;
  };

  const foundFields = search
    ? fields.filter(item => Object.entries(item).some(([key, value]) => {
      const subField = field.fields?.find(f => f.key === key);
      if (!subField) return false;
      const displayValue = getDisplayValue(subField, value);
      return displayValue.toLowerCase().includes(search.toLowerCase());
    }))
    : fields.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <div className={s.listContainer}>
      <label className="body-sm-bold">{field.label}</label>
      {field.description && <p className="caption">{field.description}</p>}
      {field.view === 'table' && !isPreview
        ? (
          <div className={s.tableElements}>
            <SearchInput label="Search items" hideLabel setSearch={setSearch} placeholder="Search items" className={s.search} />
            <Table className={s.table}>
              <THead>
                <TableRow>
                  {foundFields.length > 0 && <TH />}
                  {(field.fields ?? [])
                    .filter(subField => subField.isViewableInTableView)
                    .map(subField => (
                      <TH key={subField.id}>
                        <span className="caption" style={{ color: 'var(--color-gray-75)', whiteSpace: 'nowrap' }}>
                          {subField.label}
                        </span>
                      </TH>
                    ))}
                  {foundFields.length > 0 && !isPreview && !field.disabled && <TH />}
                </TableRow>
              </THead>
              <TBody>
                {foundFields.length > 0
                  ? (
                    foundFields
                      .map(item => {
                        const originalFieldsIndex = fields.findIndex(f => f.id === item.id);
                        return (
                          <TableRow key={item.id}>
                            <TD>
                              <Buttons
                                isWrapper
                                className={s.editButton}
                                onClick={() => setModalOpen(originalFieldsIndex)}
                              >
                                {!isPreview && !field.disabled ? <PencilSimple size={16} /> : <Eye size={16} />}
                              </Buttons>
                            </TD>
                            {field.fields?.filter(subField => subField.isViewableInTableView)
                              .map((subField, index) => (
                                <TD key={subField.id}>
                                  <span className={s.cell} style={{ whiteSpace: 'nowrap' }}>
                                    {(subField.isEditableInTableView && useEditableInTableView)
                                      ? <NonListFormField
                                        key={subField.id}
                                        listField={field}
                                        index={index}
                                        field={{
                                          ...subField,
                                          description: '',
                                          key: `${field.key}[${originalFieldsIndex}].${subField.key}`,
                                          label: '',
                                          ...subField.type === 'single-select' && { native: true, placeholder: 'Select an option', type: 'selection' },
                                          ...subField.type === 'currency' && { nativeSelect: true },
                                        }}
                                        isPreview={isPreview}
                                        control={control}
                                        rules={rules[subField.key]}
                                      />
                                      : <Controller
                                        name={`${field.key}[${originalFieldsIndex}].${subField.key}`}
                                        control={control}
                                        render={({ field: { value } }) => (
                                          <span style={value ? undefined : { color: 'var(--color-gray-25)' }}>
                                            {getDisplayValue(subField, value)}
                                          </span>
                                        )}
                                      />}
                                  </span>
                                </TD>
                              ))}
                            {!isPreview && !field.disabled && (
                              <TD>
                                <button
                                  className={s.removeItemButtonRow}
                                  type="button"
                                  onClick={() => {
                                    if (window.confirm('Are you sure you want to remove this item?')) {
                                      remove(originalFieldsIndex);
                                      if (foundFields.length === 1 && currentPage > 1) {
                                        setCurrentPage(currentPage - 1);
                                      }
                                    }
                                  }}
                                >
                                  <MinusCircle weight="fill" /> Remove Item
                                </button>
                              </TD>
                            )}
                          </TableRow>
                        );
                      })
                  )
                  : (
                    <TableRow>
                      <TD colSpan={(field.fields ?? []).length + 1} label="No items found" />
                    </TableRow>
                  )}
              </TBody>
            </Table>
            <div className={s.tableFooter}>
              {!isPreview && !field.disabled && <button className={s.addItemButton} onClick={() => {
                append(initializeSubFields());
                if (foundFields.length >= itemsPerPage) {
                  setCurrentPage(currentPage + 1);
                }
                setModalOpen(fields.length);
              }}>
                <PlusCircle weight="fill" /> Add Item
              </button>}
              {!search && <>
                <PaginationControls
                  page={currentPage}
                  numPages={Math.ceil(fields.length / itemsPerPage)}
                  onChangePage={setCurrentPage}
                  hideArrows
                  className={s.pagination}
                />
                <CustomSelect
                  native
                  value={String(itemsPerPage)}
                  id="itemsPerPage"
                  options={PAGINATION_OPTIONS}
                  onChange={(value) => {
                    const newItemsPerPage = Number(value) as 25 | 50 | 100;
                    setItemsPerPage(newItemsPerPage);
                    setCurrentPage(1);
                  }}
                  className={s.pagesSelect}
                />
              </>}
            </div>
          </div>
        )
        : (
          <>
            {fields.map((item, index) => (
              <div key={item.id} className={s.listItem}>
                {(field.fields ?? [])
                  .map(subField => (
                    <NonListFormField
                      key={subField.id}
                      listField={field}
                      index={index}
                      field={{ ...subField, key: `${field.key}[${index}].${subField.key}` }}
                      control={control}
                      rules={rules[subField.key]}
                      isPreview={isPreview}
                    />
                  ))}
                {!isPreview && !field.disabled && (
                  <button
                    className={s.removeItemButton}
                    type="button"
                    onClick={() => {
                      if (window.confirm('Are you sure you want to remove this item?')) {
                        remove(index);
                      }
                    }}
                  >
                    <MinusCircle weight="fill" /> Remove Item
                  </button>
                )}
              </div>
            ))}
            {!isPreview && !field.disabled && (
              <button className={s.addItemButton} onClick={() => append(initializeSubFields())}>
                <PlusCircle weight="fill" /> Add Item
              </button>
            )}
          </>
        )}
      {modalOpen !== undefined && (
        <Modal

          title={`Edit ${field.label} item`}
          onClose={async () => {
            const isValid = await validateListItem();
            if (!isValid) {
              if (window.confirm('Are you sure you want to close this modal? This list item won’t be saved.')) {
                remove(modalOpen);
                setModalOpen(undefined);
              }
            } else {
              setModalOpen(undefined);
            }
          }}

        >
          <div
            className={s.modal}
          >
            {(field.fields ?? [])
              .map(subField => (
                <NonListFormField
                  key={subField.id}
                  index={modalOpen}
                  listField={field}
                  field={{ ...subField, key: `${field.key}[${modalOpen}].${subField.key}` }}
                  control={control}
                  rules={rules[subField.key]}
                  isPreview={isPreview}
                />
              ))}
            {!isPreview && !field.disabled && (
              <Button
                variant="primary"
                size="md"
                onClick={async () => {
                  const isValid = await validateListItem();
                  if (isValid) {
                    setModalOpen(undefined);
                  } else {
                    addToast(ToastTypeVariants.ERROR, 'Please fill out all required fields.');
                  }
                }}
                className={s.saveButton}
              >
                  Save
              </Button>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default List;
