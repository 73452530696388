import { useEffect, useMemo, useRef, useState } from 'react';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
import useIPGeolocation from '@/hooks/useIPGeolocation';
import InputField from '../Input';
import validate from './validate';
import s from './PhoneInput.module.scss';

type Iti = ReturnType<typeof intlTelInput>;

type Props = {
  id: string;
  value: string | null;
  placeholder?: string;
  onChange: (phoneNumber: string, country: string, nationalPhoneNumber?: string) => void;
  onError?: (error: string | null) => void;
  label: string;
  disabled?: boolean;
  required?: boolean;
  error?: string;
  isTouched?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
};

const PhoneInput: React.FC<Props> = ({ id, disabled, value: inputValue, placeholder, label, onChange, error, onError, required = false, onBlur }) => {
  const value = inputValue ?? '';
  const [internalValue, setInternalValue] = useState(value);
  const [, setInternalError] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const itiRef = useRef<Iti | null>(null);
  const [itiIsReady, setItiIsReady] = useState(false);

  const onChangeRef = useRef<Props['onChange'] | null>();
  onChangeRef.current = onChange;
  const onErrorRef = useRef<Exclude<Props['onError'], undefined> | null>();
  onErrorRef.current = onError;

  const [geolocation] = useIPGeolocation();
  const isoCode = geolocation?.country?.iso_code;
  const inputElement = inputRef.current;

  useEffect(() => {
    if (inputElement) {
      try {
        itiRef.current = intlTelInput(inputElement, {
          autoPlaceholder: 'off',
          // @ts-expect-error invalid prop, but needed
          countryOrder: ['us', 'gb', 'au', 'de', 'se', 'ie', 'nl'],
          nationalMode: true,
          separateDialCode: true,
          utilsScript: '/intl-tel-input/utils.js',
        });
        itiRef.current.promise
          .then(() => setItiIsReady(true))
          .catch(err1 => {
            console.error('-E2-', err1);
          });
      } catch (err2) {
        console.error('-E1-', err2);
      }
    }
  }, [inputElement]);

  useEffect(() => {
    if (itiIsReady && itiRef.current) {
      const phoneNumber = itiRef.current.getNumber();
      const nationalPhoneNumber = itiRef.current.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
      setInternalValue(phoneNumber);
      if (onChangeRef.current) {
        const countryData = itiRef.current.getSelectedCountryData();
        const country = (countryData.iso2 || '').toUpperCase();
        onChangeRef.current(phoneNumber, country, nationalPhoneNumber);
      }
    }
  }, [itiIsReady]);

  const countryChangeListener = useMemo(
    () => () => {
      if (itiIsReady && itiRef.current) {
        const phoneNumber = itiRef.current.getNumber();
        const nationalPhoneNumber = itiRef.current.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
        setInternalValue(phoneNumber);
        if (onChangeRef.current) {
          const countryData = itiRef.current.getSelectedCountryData();
          const country = (countryData.iso2 || '').toUpperCase();
          onChangeRef.current(phoneNumber, country, nationalPhoneNumber);
        }
      }
    },
    [],
  );

  useEffect(() => {
    if (inputElement) {
      inputElement.addEventListener('countrychange', countryChangeListener);
    }

    return () => inputElement?.removeEventListener('countrychange', countryChangeListener);
  }, [inputElement, countryChangeListener]);

  useEffect(() => {
    if (isoCode && itiRef.current) {
      itiRef.current.setCountry(isoCode);
      if (onErrorRef.current) {
        onErrorRef.current(null);
      }
    }
  }, [isoCode]);

  useEffect(() => {
    if (itiRef.current) {
      const countryData = itiRef.current.getSelectedCountryData();
      const countryCode = countryData?.dialCode;
      const newError = validate({
        countryCode,
        isRequired: required,
        value: internalValue,
      });
      setInternalError(newError);
      if (onErrorRef.current) {
        onErrorRef.current(newError);
      }
    }
  }, [internalValue, required]);

  const handleChangeInput = (enteredValue: string): void => {
    if (itiRef.current) {
      const countryData = itiRef.current.getSelectedCountryData();
      const countryCode = countryData.dialCode;
      const hasPlus = /^\+/.test(value);
      const enteredValueNoFormatting = `${hasPlus ? '+' : ''}${value.replace(/[^0-9]/g, '')}`;
      const phoneNumber = itiRef.current.getNumber() || enteredValueNoFormatting;
      const nationalPhoneNumber = itiRef.current.getNumber(intlTelInputUtils.numberFormat.NATIONAL);
      const enteredValueWithCountryCode = `+${countryCode}${enteredValueNoFormatting}`;
      const newValue = phoneNumber === enteredValueNoFormatting || phoneNumber === enteredValueWithCountryCode ? enteredValue : phoneNumber;
      if (onChangeRef.current) {
        const country = (countryData.iso2 || '').toUpperCase();
        onChangeRef.current(phoneNumber, country, nationalPhoneNumber);
      }
      setInternalValue(newValue);
    }
  };

  const name = id;

  return (
    <div className={s.wrapper}>
      <div className={s.phoneInputWrapper}>
        <InputField
          ref={inputRef}
          id={id}
          name={name}
          value={internalValue}
          onChange={handleChangeInput}
          placeholder={placeholder}
          label={label}
          optional={!required}
          error={error}
          inputMode="tel"
          disabled={disabled}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default PhoneInput;
