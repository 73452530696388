import { RegisterOptions } from 'react-hook-form';
import { Questionnaire } from '@/typings/Questionnaire';
import customValidators, { Validation } from '@/helpers/validation/index';

export const getValidationRules = (field: Questionnaire.FieldCommon): RegisterOptions => {
  const validation: Validation = field.validation || {};
  const rules: RegisterOptions = {};

  if (validation.min) rules.min = validation.min;
  if (validation.max) rules.max = validation.max;
  if (validation.minLength) rules.minLength = validation.minLength;
  if (validation.maxLength) rules.maxLength = validation.maxLength;
  if (validation.type) rules.pattern = validation.pattern;
  if (validation.pattern) rules.pattern = validation.pattern;
  if (field.isRequired) rules.required = "Required";

  // Handle custom validators
  Object.keys(customValidators).forEach(validator => {
    if (validation[validator as keyof typeof customValidators]) {
      rules.validate = {
        ...rules.validate,
        [validator]: () =>
          customValidators[validator as keyof typeof customValidators](
            validation[validator as keyof typeof customValidators]!,
          ) ?? undefined,
      };
    }
  });
  return rules;
};

const generateValidationSchema = (
  fields: Questionnaire.FieldCommon[],
): { [key: string]: RegisterOptions } => {
  const schema: { [key: string]: RegisterOptions } = {};

  fields.forEach(field => {
    const rules = getValidationRules(field);

    schema[field.key] = rules;
  });

  return schema;
};

export default generateValidationSchema;
