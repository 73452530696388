import { useContext, useEffect, useState } from 'react';
import { mixpanelTrack } from '@providers/Mixpanel';
import Shimmer from '@components/Shimmer';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { FormProviders, FormStatus } from '@typings/api-models';
import promiseRetry from 'promise-retry';
import Header from '@components/DesignLibrary/Header';
import userWorkflowToContext from '@helpers/userWorkflowToContext';
import PageContext from '@components/DesignLibrary/PageContext';
import Spacer from '@components/Spacer';
import BannerStatus from '@components/DesignLibrary/BannerStatus';
import { getUrl, IDENTIFIERS } from '@helpers/urlsHelper';
import DashboardContext from '@layouts/DashboardLayout/DashboardContext';
import removeNullish from '@helpers/removeNullish';
import classNames from 'classnames';
import UserProfileContext from '@layouts/UserProfileLayout/UserProfileContext';
import AnvilFormDetails from './AnvilFormDetails';
import OtherFormDetails from './OtherFormDetails';
import NoodleQuestionnaireFormDetails from './NoodleQuestionnaireFormDetails';
import s from './FormRequestDetails.module.scss';

type Props = {
    formRequestId: string;
    creatorSlug: string;
};

type ClientFormRequest = Awaited<ReturnType<typeof tsClient.formRequests.getFormRequestById>>;

const FormRequestDetails: React.FC<Props> = ({ formRequestId, creatorSlug }) => {
  const [formRequestDetails, setFormRequestDetails] = useState<ClientFormRequest| null>(null);

  const { data: userWorkflow, getData: getUserWorkflowById } = useNoodleApi(tsClient.workflows.getUserWorkflowById, {
    toastOnError: true,
  });

  const { getData: getFormRequestById } = useNoodleApi(tsClient.formRequests.getFormRequestById, {
    toastOnError: true,
  });

  const { fetchingState: updateFormRequestFetchingState, getData: updateFormRequest } = useNoodleApi(tsClient.formRequests.updateFormRequest, {
    toastOnError: true,
  });

  const { isInDashboard } = useContext(DashboardContext);
  const { isInUserProfile } = useContext(UserProfileContext);

  useEffect(() => {
    mixpanelTrack('FormRequest - viewed', { creatorSlug, formRequestId });
  }, [creatorSlug, formRequestId]);

  useEffect(() => {
    if (formRequestId) {
      const fetchFormRequest = async (): Promise<void> => {
        const response = await getFormRequestById({ formRequestId });
        if (response.data) {
          setFormRequestDetails(response.data);
        }
      };
      fetchFormRequest();
    }
  }, [formRequestId, getFormRequestById]);

  useEffect(() => {
    if(formRequestDetails?.userWorkflowId) {
      getUserWorkflowById({
        userWorkflowId: formRequestDetails?.userWorkflowId,
      });
    }
  },[getUserWorkflowById, formRequestDetails?.userWorkflowId]);

  const handleEditFormClick = async (): Promise<void> => {
    const response = await updateFormRequest({
      formRequestId,
      status: FormStatus.IN_PROGRESS,
    });
    if (response.data) {
      setFormRequestDetails(response.data);
    }
  };

  const handleForgeComplete = async (): Promise<void> => {
    const formRequestResponse = await promiseRetry(async (retry) => {
      try {
        const formRequest = await getFormRequestById({ formRequestId });
        if (!formRequest.data?.response) {
          throw new Error('No formRequest.response so retry');
        }
        return formRequest;
      } catch(error) {
        return retry(error);
      }
    }, {
      factor: 1,
      minTimeout: 750,
      retries: 10,
    });
    if (formRequestResponse.data) {
      setFormRequestDetails({
        ...formRequestResponse.data,
        completedAt: formRequestResponse.data.completedAt || new Date().toISOString(),
        status: FormStatus.COMPLETED,
      });
    }
  };

  if (!formRequestDetails) {
    return (
      <div className={s.wrapper}>
        <Shimmer />
      </div>
    );
  }

  const isSkipped = formRequestDetails.status === FormStatus.SKIPPED;
  const isCompleted = formRequestDetails.status === FormStatus.COMPLETED;
  const completed = formRequestDetails?.completedAt || isCompleted;

  const actions = [
    completed ? {label: 'Edit Responses', onClick: handleEditFormClick} : null,
    !isInDashboard && !isInUserProfile
      ? {
        href: getUrl(IDENTIFIERS.USER_PROFILE, {
          creatorSlug: creatorSlug || '',
          tab: formRequestDetails?.userWorkflowId ? 'activity' : 'chat',
          userWorkflowId: formRequestDetails?.userWorkflowId || undefined,
        }),
        label: 'Back to profile',
      }
      : null,
  ].filter(removeNullish);

  const bannerStatus = completed
    ? {label: 'This form request has been completed.', status: 'success' as const}
    : (isSkipped
      ? {label: 'This form request has been skipped.', status: 'warning' as const}
      : null
    );

  const formProvider = formRequestDetails.template.provider;

  return (
    <>
      <Header
        title={formRequestDetails.template.name}
        actions={actions}
      />
      <PageContext items={userWorkflowToContext(userWorkflow)} isFetching={!userWorkflow} />
      {!formRequestDetails?.completedAt && formProvider !== FormProviders.Noodle && <Spacer size={16}/>}

      <div className={formProvider === FormProviders.Noodle
        ? classNames(
          s.noodleWrapper,
          formRequestDetails.completedAt && s.noodleWrapperCompleted,
        )
        : s.wrapper}>
        {bannerStatus
          ? <BannerStatus label={bannerStatus.label} status={bannerStatus.status}></BannerStatus>
          : undefined
        }
        {formProvider !== FormProviders.Noodle && <Spacer size={8}/>}
        {!updateFormRequestFetchingState.isFetching && formProvider === FormProviders.Anvil && !isSkipped && (
          <AnvilFormDetails
            formRequest={formRequestDetails}
            onForgeComplete={handleForgeComplete}
          />
        )}
        {!updateFormRequestFetchingState.isFetching && formProvider === FormProviders.Noodle && !isSkipped && (
          <NoodleQuestionnaireFormDetails formRequest={formRequestDetails} onComplete={handleForgeComplete} />
        )}
        {formProvider !== FormProviders.Anvil && formProvider !== FormProviders.Noodle && !isSkipped && (
          <OtherFormDetails
            formRequest={formRequestDetails}
          />
        )}
      </div>
    </>
  );

};
export default FormRequestDetails;
