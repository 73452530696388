import { useMemo } from 'react';
import * as format from '@format';
import * as ApiModels from '@typings/api-models';
import ListChecks from '@components/Icons/ListChecks';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseMultipleChoiceField;
};

const MultipleChoiceAnswer: React.FC<Props> = ({ field }) => {
  const str = useMemo<string>(() => {
    if (field.allowMultipleSelections) {
      if (field.type === 'multi-select') {
        return format.csl(field.answer?.map(({ label }) => label) || []);
      }

      return format.csl(field.answer || []);
    }

    if (field.type === 'single-select' || field.type === 'selection') {
      return field.answer?.label || '';
    }

    return field.answer || '';
  }, [field]);

  return (
    <div className={s.answer}><ListChecks weight='regular' /><BaseAnswer answer={str} /></div>
  );
};

export default MultipleChoiceAnswer;
