import ProgressIndicator from '@/components/ProgressIndicator';
import XCircle from '@/components/Icons/XCircle';
import s from '../IconButton.module.scss';
import type { Interface } from '../typings';

const DeleteButton: React.FC<Interface> = ({ disabled, loading, onClick }) => {
  if (loading) {
    return (
      <ProgressIndicator size={20}/>
    );
  }
  return (
    <button
      className={s.wrapper}
      disabled={disabled}
      onClick={onClick}
    >
      <XCircle weight="fill" size={20} color="var(--color-error)" />
    </button>
  );
};

export default DeleteButton;