import React, { ChangeEvent, useCallback, useRef } from 'react';
import classNames from 'classnames';

import s from './TextArea.module.scss';
import Label from '../shared/Label';

export type TextAreaProps = {
  label: string;
  name?: string;
  description?: string;
  disabled?: boolean;
  value?: string | null;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  hint?: string;
  onChange: (value: string, event: ChangeEvent<HTMLTextAreaElement>) => void;
} & Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'onChange' | 'value'>;

const TextArea: React.FC<TextAreaProps> = ({
  label,
  description,
  value,
  placeholder,
  disabled,
  error,
  optional,
  hint,
  name,
  onChange,
  ...props
}) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>): void => {
    onChangeRef.current(event.target.value, event);
  }, []);

  return (
    <Label
      label={label}
      required={!optional}
      description={description}
      errorMessage={error}
      hint={hint}
    >
      <textarea
        name={name}
        value={value ?? ''}
        placeholder={placeholder}
        required={!optional}
        onChange={handleChange}
        className={classNames(s['input-field'], s.textarea, !!error && s.error)}
        disabled={disabled}
        {...props}
      />
    </Label>
  );
};

export default TextArea;
