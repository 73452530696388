import { FC } from 'react';
import classNames from 'classnames';
import CaretCircleDown from '@components/Icons/CaretCircleDown';
import s from './TableCell.module.scss';

export type THProps = {
  compact?: never;
  colSpan?: number;
  head: true;
  label?: string;
} & (
  | {
      columnId: string;
      sort: {
        onSort: (columnId: string, direction: 'asc' | 'desc') => unknown;
        currentSortByField: string;
        currentSortDirection: 'asc' | 'desc';
      }
    }
  | {
      columnId?: never;
      sort?: never;
    }
);

export type TDProps = {
  compact?: boolean
  head?: false;
  label?: string;
  columnId?: never
  sort?: never;
  colSpan?: number;
};

type Props = THProps | TDProps;

const TableCell: FC<Props> = ({
  children,
  columnId,
  colSpan,
  compact,
  head,
  label,
  sort,
}) => {
  const handleSortClick = (): void => {
    if (sort) {
      // if changing column, sort asc.
      // if not changing column, toggle.
      let newDirection: 'asc' | 'desc' = 'desc';
      if (columnId === sort.currentSortByField) {
        newDirection = sort.currentSortDirection === 'asc' ? 'desc' : 'asc';
      }
      sort.onSort(columnId, newDirection);
    }
  };

  const isSortable = head && sort;
  const showSort = isSortable && (columnId === sort.currentSortByField);
  const Tag = head ? 'th' : 'td';
  const ButtonOrDiv = isSortable ? 'button' : 'div';

  return (
    <Tag className={classNames(s.tag, colSpan && s.section)} colSpan={colSpan}>
      <ButtonOrDiv
        className={isSortable ? s.cellButton : s.cell}
        {...(head && sort)
          ? { onClick: handleSortClick }
          : {}
        }
      >
        <div>
          {label && (
            <p
              className={classNames(
                (compact || head) ? 'caption' : 'body-sm',
                s.label,
                head && (showSort ? s.labelHighlighted : s.labelRegular) || '',
              )}
            >
              {label}
            </p>
          )}
          {children}
        </div>
        {showSort && (
          <div className={classNames(s.sort, sort.currentSortDirection === 'asc' ? s.sortAsc : s.sortDesc)}>
            <CaretCircleDown size={16} weight="fill" fill="var(--color-gray-100)" />
          </div>
        )}
      </ButtonOrDiv>
    </Tag>
  );
};

export default TableCell;
