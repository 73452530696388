import BaseEvaluateExpressionError from './BaseEvaluateExpressionError';

class UndefinedVariableError extends BaseEvaluateExpressionError {
  missingVariables: string[];

  constructor(...missingVariables: string[]) {
    super('Undefined variable(s)');
    this.missingVariables = missingVariables.length === 0 ? ['unknown'] : missingVariables;
  }
}

export default UndefinedVariableError;
