export const formatDate = (date: Date): string =>
  [
    date.getFullYear(),
    (date.getMonth() + 1).toString().padStart(2, '0'),
    date.getDate().toString().padStart(2, '0'),
  ].join('-');

const calculateAge = (birthday: string): number => {
  const birthDate = new Date(birthday);
  if (Number.isNaN(birthDate)) {
    throw new Error("Invalid date format. Please use 'YYYY-MM-DD'.");
  }

  // Get the current date, but need to format it as YYYY-MM-DD and call new Date so that tz matches the birthday.
  // Without this the calculation will be wrong when the local date is different than the UTC date.
  const today = new Date(formatDate(new Date()));
  if (Number.isNaN(today)) {
    throw new Error('Failed to calculate todays date');
  }

  const birthYear = birthDate.getFullYear();
  const birthMonth = birthDate.getMonth();
  const birthDay = birthDate.getDate();

  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();
  const currentDay = today.getDate();

  let age = currentYear - birthYear;

  if (
    currentMonth < birthMonth
    || (currentMonth === birthMonth && currentDay < birthDay)
  ) {
    age -= 1;
  }

  return age;
};

const isValidAge = ({
  birthday,
  limit,
  symbol: type,
}: {
  birthday: string;
  limit: number;
  symbol: '>=' | '>' | '=' | '<' | '<=';
}): boolean => {
  const age = calculateAge(birthday);
  if (type === '>=') {
    return age >= limit;
  }

  if (type === '>') {
    return age > limit;
  }

  if (type === '=') {
    return age === limit;
  }

  if (type === '<') {
    return age < limit;
  }

  if (type === '<=') {
    return age <= limit;
  }

  return false;
};

const validateAge = ({
  birthday,
  limit,
  symbol: type,
  message,
}: Parameters<typeof isValidAge>[0] & { message: string }): string | null =>
  isValidAge({ birthday, limit, symbol: type }) ? null : message;

export default validateAge;
