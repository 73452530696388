import ts from 'typescript';
import { BaseEvaluateExpressionError, EvaluateExpressionError } from '../errors';
import type { Evaluator } from '../types';

const evaluatePrefixUnaryExpression: Evaluator<ts.PrefixUnaryExpression> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  const operandValue = evaluators.expression({
    evaluators,
    log,
    node: node.operand,
    variables,
  });
  if (operandValue instanceof BaseEvaluateExpressionError) {
    return operandValue;
  }
  if (node.operator === ts.SyntaxKind.MinusToken) {
    if (typeof operandValue === 'number') {
      return -1 * operandValue;
    }
  } else if (node.operator === ts.SyntaxKind.PlusToken) {
    if (typeof operandValue === 'number') {
      return operandValue;
    }
  } else if (node.operator === ts.SyntaxKind.ExclamationToken) {
    return !operandValue;
  }

  const error = new EvaluateExpressionError('Unsupported PrefixUnaryExpression', { details: `operator: ${node.operator}`, kind: node.kind });
  log.error(error, 'evaluate-expression-error');
  log.info({ node, variables }, 'evaluate-expression-error');

  return error;
};

export default evaluatePrefixUnaryExpression;
