import classNames from 'classnames';
import { ReactNode } from 'react';
import s from './Label.module.scss';

export type LabelProps = {
  label: string;
  compact?: boolean;
  hideLabel?: boolean;
  description?: string;
  hint?: string;
  errorMessage?: string;
  required?: boolean;
  children?: ReactNode;
  id?: string;
  className?: string;
};

const Label: React.FC<LabelProps> = ({
  label,
  compact,
  hideLabel,
  description,
  hint,
  errorMessage,
  required,
  children,
  id,
  className,
}) => {
  const Tag = hideLabel ? 'div' : 'label';
  return (
    <Tag className={classNames(compact ? s.compactContainer : s.container, className)} id={id}>
      {!hideLabel && (label || description) && (
        <>
          <div className={s.top}>
            {label && <p className={compact ? s.compactLabel : 'body-sm-bold'}>
              <span
                style={
                  errorMessage ? { color: 'var(--color-error)' } : undefined
                }
              >
                {label}
              </span>
              {!required && (
                <span className={compact ? s.compactOptional : 'body-sm'}>
                  Optional
                </span>
              )}
            </p>}
            {description && <p className="caption">
              <span className={s.description}>{description}</span>
              {!required && !label && (
                <span className={compact ? s.compactOptional : 'caption'}>
                  &nbsp;&nbsp;(Optional)
                </span>
              )}
            </p>}
          </div>
        </>
      )}
      {children}
      {!hideLabel && (errorMessage || hint) && (
        <p className={errorMessage ? s.error : s.hint}>
          {errorMessage || hint}
        </p>
      )}
    </Tag>
  );
};

export default Label;
