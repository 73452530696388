import ts from 'typescript';
import { BaseEvaluateExpressionError } from '../errors';
import { Evaluator, EvaluateResponse } from '../types';

const evaluateIdentifier: Evaluator<ts.Identifier> = ({
  log,
  node,
  variables,
}) => {
  const variableName = node.escapedText.toString();
  if (variables[variableName] !== undefined) {
    return variables[variableName] as Exclude<EvaluateResponse, BaseEvaluateExpressionError>; // @todo some kind of validation here.
  }

  log.info({ node, variableName, variables }, 'evaluate-expression-undefined-variable');

  return undefined;
};

export default evaluateIdentifier;
