import s from './Table.module.scss';

type Props = {
  className?: string;
  disableScroll?: boolean;
};

const Table: React.FC<Props> = ({ children, className, disableScroll }) => (
  <div className={s.container} {...disableScroll && {
    style: {
      overflowX: 'unset',
    },
  }}>
    <table className={className} cellSpacing='0' cellPadding='0'>
      {children}
    </table>
  </div>
);

export default Table;