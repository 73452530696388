import { FC } from 'react';

const CircularProgress: FC<{
    size: number;
    progress: number;
    isWhite?: boolean;
    noTransition?: boolean;
  }> = ({ size, progress, isWhite, noTransition }) => {
    const strokeWidth = 2;
    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    const offset = ((100 - progress) / 100) * circumference;

    return (
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
        style={{ transform: 'rotate(-90deg)' }}
      >
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          strokeWidth={2}
          stroke={isWhite ? "#FFFFFF20" : "#55555520"}
        />
        <circle
          cx={center}
          cy={center}
          r={radius}
          fill="none"
          strokeWidth={strokeWidth}
          stroke={isWhite ? "#FFFFFF" : "var(--color-success)"}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap="round"
          style={{ transition: `stroke-dashoffset ${noTransition ? '0.1' : '0.5'}s ease-in-out` }}
        />
      </svg>
    );
  };

export default CircularProgress;
