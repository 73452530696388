type Props = {
  className?: string;
};

const THead: React.FC<Props> = ({ children, className }) => (
  <thead className={className}>
    {children}
  </thead>
);

export default THead;