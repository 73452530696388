import React, {
  ChangeEvent,
  forwardRef,
  ForwardRefRenderFunction,
  useCallback,
  useRef,
} from 'react';
import classNames from 'classnames';
import Label from '../shared/Label';

import s from './Input.module.scss';

export type InputProps = {
  id?: string;
  name?: string;
  label?: string;
  compact?: boolean;
  disabled?: boolean;
  description?: string;
  value?: string | null;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  hint?: string;
  suffix?: string;
  prefix?: React.ReactNode;
  type?: 'number' | 'text';
  min?: number;
  inputMode?:
    | 'text'
    | 'email'
    | 'search'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal';
  onChange: (value: string, event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
};

const Input: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  {
    id,
    name,
    label,
    compact,
    disabled,
    description,
    value,
    placeholder,
    error,
    optional,
    hint,
    suffix,
    prefix,
    type = 'text',
    min,
    inputMode,
    onChange,
    onBlur,
  },
  ref,
) => {
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    onChangeRef.current(event.target.value, event);
  }, []);

  return (
    <Label
      label={label || ''}
      hideLabel={!label && !description}
      required={!optional}
      description={description}
      errorMessage={error}
      hint={hint}
      compact={compact}
    >
      <div className={s['input-field-wrapper']}>
        {prefix ? <span className={s.prefix}>{prefix}</span> : null}
        <input
          id={id}
          ref={ref}
          name={name}
          type={type}
          value={value ?? ''}
          placeholder={placeholder}
          disabled={disabled}
          required={!optional}
          onChange={handleChange}
          onBlur={onBlur}
          min={min}
          className={classNames(
            s['input-field'],
            !!error && s.error,
            !!suffix && s['has-suffix'],
          )}
          inputMode={inputMode}
        />
        {suffix ? <span className={s.suffix}>{suffix}</span> : null}
      </div>
    </Label>
  );
};

export default forwardRef(Input);
