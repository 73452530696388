import Coins from '@components/Icons/Coins';
import * as ApiModels from '@typings/api-models';
import * as format from '@format';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseCurrencyField;
};

const CurrencyAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><Coins /><BaseAnswer answer={answer === null ? null : format.price.withCurrency(answer.amount, answer.currency)} /></div>
);

export default CurrencyAnswer;
