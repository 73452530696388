import classNames from 'classnames';
import { ReactNode } from 'react';
import s from './Fieldset.module.scss';

export type FieldsetProps = {
  label: string;
  description?: string;
  hint?: string;
  error?: string;
  required?: boolean;
  children?: ReactNode;
};

const Fieldset: React.FC<FieldsetProps> = ({
  label,
  description,
  hint,
  error,
  required,
  children,
}) => (
  <fieldset className={s.container}>
    <legend>
      {label && <p className="body-sm-bold">
        <span
          style={error ? { color: 'var(--color-error)' } : undefined}
        >
          {label}
        </span>
        {!required && <span className="body-sm">Optional</span>}
      </p>}
      {description && <p className={classNames("caption", s.description)}>{description}</p>}
    </legend>
    {children}
    {(error || hint) && (
      <p className={error ? s.error : s.hint}>{error || hint}</p>
    )}
  </fieldset>
);

export default Fieldset;
