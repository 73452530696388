import type { Evaluators } from '../types';
import binaryExpression from './binaryExpression';
import conditionalExpression from './conditionalExpression';
import elementAccessExpression from './elementAccessExpression';
import expression from './expression'; // @todo - should this just be in expressionStatment?
import expressionStatement from './expressionStatement';
import identifier from './identifier';
import numericLiteral from './numericLiteral';
import parenthesizedExpression from './parenthesizedExpression';
import prefixUnaryExpression from './prefixUnaryExpression';
import propertyAccessExpression from './propertyAccessExpression';
import stringLiteral from './stringLiteral';

const evalauators: Evaluators = {
  binaryExpression,
  conditionalExpression,
  elementAccessExpression,
  expression,
  expressionStatement,
  identifier,
  numericLiteral,
  parenthesizedExpression,
  prefixUnaryExpression,
  propertyAccessExpression,
  stringLiteral,
};

export default evalauators;
