import Link from '@components/Icons/Link';
import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseUrlField;
};

const UrlAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><Link weight='regular' /><BaseAnswer answer={answer} /></div>
);

export default UrlAnswer;
