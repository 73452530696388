/* eslint-disable @typescript-eslint/no-namespace, import/no-unused-modules, @typescript-eslint/no-unused-vars */

import { Validation } from '@/helpers/validation/index';
import * as QuestionnaireApiModels from './questionnaires-api-models';
import type { ValueOf } from './common';

type PersonName = {
  firstName: string;
  middleName: string;
  lastName: string;
};

type Line2Type = 'apartment' | 'floor' | 'suite' | null;

type AddressCommon = {
  line1: string;
  line2: string;
  line2Type: Line2Type;
  city: string;
};

type AddressUS = AddressCommon & {
  state: string; // @todo - literal
  zipCode: string;
};

type AddressInternational = AddressCommon & {
  country: string;
  postCode: string;
};

export type SelectOptionStyle = 'checkbox' | 'radio-button' | 'button';

export type FieldType =
  | 'short-text'
  | 'long-text'
  | 'number'
  | 'numeric' // short text with filter?
  | 'multi-select'
  | 'single-select'
  | 'selection'
  | 'percent'
  | 'currency'
  | 'date'
  | 'phone-number'
  | 'ssn'
  /* composite fields */
  | 'name'
  | 'address-us'
  | 'address-international'
  | 'list'
  /* for organizing */
  // | 'break'
  | 'explanation';

export namespace Questionnaire {
  export type FieldCommon = {
    id: string;
    key: string;
    rank: number;
    label: string;
    description: string;
    type: FieldType;
    isRequired: boolean;
    disabled?: boolean;
    assignedTo: string[];
    validation?: Validation;
    placeholder?: string;
    hint?: string;
    options?: {key: string; label: string, isDefault: boolean}[];
    listFieldTemplateId: string | null;
    // eslint-disable-next-line no-use-before-define
    listItemTemplates: Questionnaire.Field[];
    view: string | null;
    isEditableInTableView: boolean;
    isViewableInTableView: boolean;
    conditionExpression: Pick<QuestionnaireApiModels.Expression, 'expression'> & {
      variables: Pick<QuestionnaireApiModels.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
    } | null;
  };

  type FieldShortText = FieldCommon & {
    type: 'short-text';
    mask?: string; // eg XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX for UUID
    validation?: Pick<
      Validation,
      'minLength' | 'maxLength' | 'type' | 'pattern'
    >;
    value?: string;
  };

  type FieldPercent = FieldCommon & {
    type: 'percent';
    validation?: Pick<Validation, 'min' | 'max'>;
    value?: number;
  };

  type FieldCurrency = FieldCommon & {
    // expectedCurrency: string;
    type: 'currency';
    validation?: Pick<Validation, 'min' | 'max'>;
    value?: {
      currency: string;
      amount: number;
    };
  };

  type FieldLongText = FieldCommon & {
    type: 'long-text';
    validation?: Pick<
      Validation,
      'minLength' | 'maxLength' | 'type' | 'pattern'
    >;
    value?: string;
  };

  // or short text with a filter function?
  type FieldNumeric = FieldCommon & {
    type: 'numeric';
    // value: string;
    mask?: string; // eg ###-##-#### for SSN
    validation?: Pick<Validation, 'minLength' | 'maxLength' | 'pattern'>;
    value?: string; // with or without masking? preferably without.
  };

  type FieldNumber = FieldCommon & {
    type: 'number';
    // value: string;
    suffix?: string; // eg '%' for percentage
    prefix?: string; // eg '$' for dollars/currency
    significantDigits?: number; // eg 0 for integer
    validation?: Pick<Validation, 'min' | 'max'>;
    value: number;
  };

  export type FieldMultiSelect<V = string> = FieldCommon & {
    type: 'multi-select';
    style: 'checkbox' | 'radio-button' | 'button';
    options: Array<{
      key: V;
      label: string;
      isDefault: boolean;
    }>;
    value?: V[];
  };

  export type FieldSingleSelect<V = string> = FieldCommon & {
    type: 'single-select';
    style: 'checkbox' | 'radio-button' | 'button';
    options: Array<{
      key: V;
      label: string;
      isDefault: boolean;
    }>;
    value?: V;
  };

  export type FieldSelection<V = string> = FieldCommon & {
    type: 'selection';
    options: Array<{
      key: V;
      label: string;
      isDefault: boolean;
    }>;
    value?: V;
  };

  type FieldDate = FieldCommon & {
    type: 'date';
    validation?: Pick<Validation, 'age'>;
    value?: string; // formatted YYYY-MM-DD.
  };

  type FieldName = FieldCommon & {
    type: 'name';
    value?: PersonName;
  };

  type FieldAddressUS = FieldCommon & {
    type: 'address-us';
    value?: AddressUS;
  };

  type FieldAddressInternational = FieldCommon & {
    type: 'address-international';
    value?: AddressInternational;
  };

  type FieldPhoneNumber = FieldCommon & {
    type: 'phone-number';
    value?: string;
  };

  type FieldSSN = FieldCommon & {
    type: 'ssn';
    value?: string;
  };

  // type FieldBreak = FieldCommon & {
  //   type: 'break';
  //   value?: never;
  // };

  type FieldExplanation = FieldCommon & {
    type: 'explanation';
    text?: string;
    value?: never;
    /* does this need to be rich?
    html: string;
    raw: Slate;
    */
  };

  export type FieldList = FieldCommon & {
    type: 'list';
    min: number;
    max: number;
    fields: Field[]; // eslint-disable-line no-use-before-define
  };

  export enum Operator {
    AND = 'AND',
    OR = 'OR',
  }

  export enum Comparison {
    Equal = 'equal',
    NotEqual = 'not-equal',
    GreaterThan = 'greater-than',
    LessThan = 'less-than',
    Contain = 'contain',
    NotContain = 'not-contain',
    Filled = 'filled',
    NotFilled = 'not-filled',
  }

  export type ConditionValue = string | number | boolean | null;

  export type ExpressionVariable = {
    id: string;
    variableName: string;
    selector: string;
    expressionId: string;
    referenceId: string;
    referenceType: 'field-template-key';
  };

  export type Condition = {
    id: string;
    dependentFieldKey: string;
    operator?: ValueOf<typeof Operator>;
    condition: ValueOf<typeof Comparison>;
    value: ConditionValue;
  };

  export type Expression = {
    expression: string;
    variables?: Pick<ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
  };

  export type Field = (
    | FieldShortText
    | FieldLongText
    | FieldNumeric
    | FieldNumber
    | FieldPercent
    | FieldCurrency
    | FieldMultiSelect
    | FieldSingleSelect
    | FieldSelection
    | FieldName
    | FieldDate
    | FieldAddressUS
    | FieldAddressInternational
    | FieldPhoneNumber
    | FieldSSN
    // | FieldBreak
    | FieldExplanation
    | FieldList
  ) & {
    conditions?: Array<Condition>; // @todo remove optional
    conditionExpression: Pick<QuestionnaireApiModels.Expression, 'expression'> & {
      variables: Pick<QuestionnaireApiModels.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
    } | null;
  };

  export type Section = {
    questionnaireTemplateId: string;
    id: string;
    isLoading: boolean;
    slug: string;
    label: string;
    description: string;
    key: string;
    fields: Field[];
    rank: number;
    conditionExpression: Pick<QuestionnaireApiModels.Expression, 'expression'> & {
      variables: Pick<QuestionnaireApiModels.ExpressionVariable, 'variableName' | 'referenceId' | 'selector'>[];
    } | null;
  };

  export type Questionnaire = {
    id: string;
    slug: string;
    label: string;
    description: string;
    version: number;
    versionNote?: string;
    sections: Section[];
    status: QuestionnaireApiModels.QuestionnaireTemplateStatus;
    assigneeTemplates: QuestionnaireApiModels.QuestionnaireAssigneeTemplate[];
    updatedAt?: Date;
    updatedByName?: string;
  };
}

export namespace Response {}

namespace Pdf {
  export type Pdf = {
    id: string;
    slug: string;
    version: number;
    fileName: string;
    label: string;
    description: string;
  };
}

export type Case = {
  id: string;
  slug: string;
  version: number;
  label: string;
  description: string;
  questionnaires: Questionnaire.Questionnaire[];
  pdfs: Pdf.Pdf[];
  // fillLinking: ???
  // responses: Response.????;
};

export type QuestionnaireFieldResponse = {
  fieldTemplateId: string;
  key: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
};

export type QuestionnaireTemplateRawJSON = {
  label: string;
  description: string;
  sections: Array<{
    key: string;
    label: string;
    description: string;
    fields: Array<{
      type: string;
      key: string;
      label: string;
      description: string;
      optional: boolean;
      assignees: Array<{
        key: string;
        label: string;
      }>;
      rank?: number;
      hint?: string;
      placeholder?: string;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options?: {key: any; label: string}[]; }>
  }>
};
