import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseIntlAddressField;
};

const IntlAddressAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}>{answer === null
    ? null
    : (
      <div>
        <div>
          <p>Line 1</p>
          <BaseAnswer answer={answer.line1} />
        </div>
        {answer.line2 && <div>
          <p>Line 2</p>
          <BaseAnswer answer={answer.line2} />
        </div>
        }
        <div>
          <p>City, Country, Post code</p>
          <BaseAnswer answer={`${answer.city}, ${answer.country} ${answer.postCode}`} />
        </div>
      </div>
    )}</div>
);

export default IntlAddressAnswer;
