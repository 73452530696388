import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseUSAddressField;
};

const USAddressAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}>{answer === null
    ? null
    : (
      <div>
        <div>
          <p>Street</p>
          <BaseAnswer answer={answer.line1} />
        </div>
        <div>
          <p>Apartment/Floor/Suite Number</p>
          <BaseAnswer answer={answer.line2 || 'N/A'} />
        </div>
        <div>
          <p>City, State, Zip</p>
          <BaseAnswer answer={`${answer.city}, ${answer.state} ${answer.zip}`} />
        </div>
      </div>
    )}</div>
);

export default USAddressAnswer;
