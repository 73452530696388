import React, { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { FieldValues } from 'react-hook-form';
import { debounce, isEqual, cloneDeep } from 'lodash';
import { useFormContext } from '@/context/FormContext';
import generateValidationSchema from '@/helpers/validation/schema';
import validateFieldCondition from '@/helpers/validateFieldCondition';
import { Questionnaire } from '@/typings/Questionnaire';
import Button from '@/components/DesignLibrary/Button';
import { useToast } from '@/hooks';
import evaluateExpression from '@helpers/evaluateExpression';
import getFieldValue from '@helpers/questionnaires/getFieldValue';
import classNames from 'classnames';
import ProgressIndicator from '@components/ProgressIndicator';
import FormField from '../FormField';
import SectionNavigation from '../SectionNavigation';
import s from './DynamicForm.module.scss';

type Props = {
  formData: Questionnaire.Questionnaire;
  isPreview?: boolean;
  completed?: boolean;
};

const formValuesToResponses = (
  formValues: Record<string, unknown>,
  sections: {
    fields: { id: string; key: string }[]
  }[],
): Array<{
  fieldTemplateId: string;
  key: string;
  value: unknown;
}> => {
  const fieldTemplates = sections.reduce((p: Record<string, string>, section) => ({
    ...p,
    ...section.fields.reduce((pp: Record<string, string>, field) => ({ ...pp, [field.key]: field.id }), {}),
  }), {});

  return Object.keys(formValues)
    .filter((key) => formValues[key] !== undefined)
    .map((key) => ({
      fieldTemplateId: fieldTemplates[key.split(':')[0]], // List items fields' keys use ":" to separate field key and index
      key,
      value: formValues[key],
    }));
};

const FormContent: React.FC<Props> = ({ formData, isPreview, completed }) => {
  const { formMethods, currentSection, setCurrentSection, getSectionProgress, onSubmit } = useFormContext();
  const { control } = formMethods;
  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedAssignee, setSelectedAssignee] = useState('All');
  const [formValues, setFormValues] = useState<Record<string, string>>(formMethods.getValues());
  const toast = useToast();

  useEffect(() => {
    const debounceWatch = debounce(setFormValues, 500);
    const subscription = formMethods.watch((value) => debounceWatch(cloneDeep(value)));
    return () => subscription.unsubscribe();
  }, [formMethods]);

  const prevResponses = useRef<{
    fieldTemplateId: string;
    key: string;
    value: unknown;
  }[]>();

  useEffect(() => {
    if (currentSection) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [currentSection]);

  const currentResponses = useMemo(
    () => formValuesToResponses(formValues, formData.sections),
    [JSON.stringify(formValues), formData.sections],
  );

  const assignees = useMemo(() => formData.assigneeTemplates, [formData.assigneeTemplates]);

  const filteredSections = useMemo(
    () =>
      formData.sections.map(section => ({
        ...section,
        fields: section.fields.filter(field =>
          selectedAssignee === 'All'
            ? true
            : field.assignedTo.includes(selectedAssignee),
        ),
      })),
    [formData.sections, selectedAssignee],
  );

  const currentSectionData = useMemo(
    () => filteredSections.find(section => section.id === currentSection),
    [filteredSections, currentSection],
  );

  const handleSave = useCallback(async (): Promise<void> => {
    setIsSaving(true);
    const success = await onSubmit(formValuesToResponses(formMethods.getValues(), formData.sections));

    if (success) {
      toast(useToast.ToastTypeVariants.SUCCESS, 'Responses saved successfully!');
    }

    setIsSaving(false);
  }, [onSubmit, toast, formData.sections, formMethods]);

  const handleSubmit = useCallback(async (data: FieldValues): Promise<void> => {
    setIsSubmitting(true);
    const asResponses = formValuesToResponses(data, formData.sections);
    const success = await onSubmit(asResponses, true);
    if (success) {
      toast(useToast.ToastTypeVariants.SUCCESS, 'Responses submitted successfully!');
    }
    setIsSubmitting(false);
  }, [formData.sections, onSubmit, toast]);

  const isLastSection = formData.sections.length > 0
    ? currentSection === formData.sections[formData.sections.length - 1].id
    : false;

  const evaluateSectionConditions = useCallback((section: Questionnaire.Section): boolean => {
    const { conditionExpression } = section;
    if (conditionExpression) {
      const variablesWithValues = conditionExpression.variables?.map((variable) => ({
        ...variable,
        value: getFieldValue(currentResponses.find(({ key }) => key === variable.referenceId)?.value),
      })) || [];
      const result = evaluateExpression({
        expression: conditionExpression.expression,
        variables: variablesWithValues.reduce((acc, val) => ({
          ...acc,
          [val.variableName]: val.value,
        }), {}),
      });
      return Boolean(result);
    }
    return true;
  }, [currentResponses]);

  const evaluateListFieldConditions = useCallback((field: Questionnaire.Field, listField: Questionnaire.Field): boolean => {
    if (field.conditionExpression && !isPreview) {
      const listValues = formMethods.getValues()[listField.key];
      const variablesWithValues = field.conditionExpression.variables.map(variable => ({
        ...variable,
        value: getFieldValue(
          // Only evaluate variables for first field in list
          listValues?.[0]?.[variable.referenceId],
        ),
      }));
      const result = evaluateExpression({
        expression: field.conditionExpression.expression,
        variables: variablesWithValues.reduce(
          (acc, val) => ({
            ...acc,
            [val.variableName]: val.value,
          }),
          {},
        ),
      });
      return Boolean(result);
    }
    return true;
  }, [currentResponses]);

  const evaluateFieldConditions = useCallback((field: Questionnaire.Field): boolean => {
    const { conditions, conditionExpression } = field;

    if (conditionExpression) {
      const variablesWithValues = conditionExpression.variables?.map((variable) => ({
        ...variable,
        value: getFieldValue(currentResponses.find(({ key }) => key === variable.referenceId)?.value),
      })) || [];
      const result = evaluateExpression({
        expression: conditionExpression.expression,
        variables: variablesWithValues.reduce((acc, val) => ({
          ...acc,
          [val.variableName]: val.value,
        }), {}),
      });
      return Boolean(result);
    }

    if (!conditions) {
      return true;
    }

    const len = conditions.length;
    for (let i = len - 1; i >= 0; i -= 1) {
      const {dependentFieldKey, operator, condition: comparison, value} = conditions[i];
      const fieldValue = currentResponses.find(({ key }) => key === dependentFieldKey)?.value;

      const result = validateFieldCondition(comparison, value, fieldValue);
      if (i === 0) {
        return result;
      }
      if (operator === Questionnaire.Operator.AND && !result) {
        return false;
      }
      if (operator === Questionnaire.Operator.OR && result) {
        return true;
      }
    }
    return true;
  }, [currentResponses]);

  const getFieldsVisibility = useCallback((sections: Questionnaire.Section[]): Record<string, boolean> => {
    const visibilityMap: Record<string, boolean> = {};

    sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (!field.listFieldTemplateId) {
          visibilityMap[field.key] = evaluateFieldConditions(field);
        } else {
          const listField = section.fields.find(f => f.id === field.listFieldTemplateId);
          if (listField) {
            visibilityMap[field.key] = evaluateFieldConditions(listField) && evaluateListFieldConditions(field, listField);
          }
        }
      });
    });

    return visibilityMap;
  }, [evaluateFieldConditions]);

  const fieldsVisibility = useMemo(() => getFieldsVisibility(filteredSections), [getFieldsVisibility, filteredSections]);

  const nextSectionIndex = formData.sections.findIndex(({ id }) => id === currentSection) + 1;

  const handleNextSection = (): void => {
    if (formData.sections.filter(evaluateSectionConditions)[nextSectionIndex]) {
      setCurrentSection(formData.sections.filter(evaluateSectionConditions)[nextSectionIndex].id);
    }
  };

  const debouncedSaveDraft = useCallback(
    debounce((data) => onSubmit(data), 1000),
    [onSubmit],
  );

  const validationSchema = useMemo(() =>
    generateValidationSchema(
      formData.sections.flatMap(section => section.fields),
    ), [formData.sections],
  );

  useEffect(() => {
    if (!isEqual(currentResponses, prevResponses.current)) {
      prevResponses.current = currentResponses;

      debouncedSaveDraft(currentResponses);
    }
  }, [currentResponses, debouncedSaveDraft]);

  return (
    <div className={classNames(s.formContainer, completed && s.completed)}>
      <SectionNavigation
        sections={formData.sections.filter(evaluateSectionConditions).map(section => ({
          id: section.id,
          isLoading: section.isLoading,
          label: section.label,
          progress: getSectionProgress(section.id, fieldsVisibility),
        }))}
        currentSection={currentSection}
        onSelectSection={setCurrentSection}
        completed={completed}
      />
      <div className={s.form}>
        <div className={s.formContent}>
          <div className={s.filterContainer}>
            <button
              className={selectedAssignee === 'All' ? s.activeFilter : ''}
              onClick={() => setSelectedAssignee('All')}
            >
                All
            </button>
            {assignees.map(assignee => (
              <button
                key={assignee.id}
                className={
                  selectedAssignee === assignee.id ? s.activeFilter : ''
                }
                onClick={() => setSelectedAssignee(assignee.id)}
              >
                {assignee.label}
              </button>
            ))}
          </div>
          <h2 className='heading-sm'>{currentSectionData?.label}</h2>
          {currentSectionData?.description && <p className="body-sm">{currentSectionData?.description}</p>}
          {currentSectionData?.isLoading && (
            <>
              <ProgressIndicator isCentered />
            </>
          )}
          {(currentSectionData?.fields ?? [])
            .sort((a, b) => a.rank > b.rank ? 1 : -1)
            .filter(({ listFieldTemplateId }) => !listFieldTemplateId)
            .map(field => (
              <div key={field.key} className={s.field}>
                <FormField
                  field={field.type === 'list'
                    ? {
                      ...field,
                      fields: (currentSectionData?.fields ?? [])
                        .filter(({ listFieldTemplateId }) => listFieldTemplateId === field.id),
                    } as Questionnaire.FieldList
                    : field}
                  control={control}
                  isPreview={isPreview}
                  rules={validationSchema[field.key]}
                />
              </div>
            ))}
          {(!isPreview && !completed) && (
            <div className={s.actions}>
              {isLastSection
                ? (
                  <Button
                    type="submit"
                    variant="primary"
                    size="md"
                    {...formMethods.formState.isValid && !formMethods.formState.isValidating
                      ? {
                        onClick: formMethods.handleSubmit(handleSubmit),
                      }
                      : {
                        onClick: async () => {
                          await formMethods.trigger();
                          toast(useToast.ToastTypeVariants.ERROR, 'Please complete all required fields.');
                        },
                      }}
                    loading={isSubmitting}
                  >
                    Submit
                  </Button>
                )
                : (
                  <Button variant="primary" size="md" onClick={handleNextSection}>
                    Next{formData.sections.filter(evaluateSectionConditions)[nextSectionIndex]?.label ? `: ${formData.sections.filter(evaluateSectionConditions)[nextSectionIndex].label}` : ''}
                  </Button>
                )}
              <Button
                loading={isSaving}
                variant="secondary"
                size="md"
                onClick={handleSave}
              >
                Save and complete later
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormContent;
