import ts from 'typescript';
import type { Evaluator } from '../types';

const evaluateExpressionStatement: Evaluator<ts.ExpressionStatement> = ({
  node,
  variables,
  log,
  evaluators,
}) => evaluators.expression({
  evaluators,
  log,
  node: node.expression,
  variables,
});

export default evaluateExpressionStatement;
