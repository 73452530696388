import { useMemo } from 'react';
import clsx from 'classnames';
import CaretLeft from '@components/Icons/CaretLeft';
import CaretRight from '@components/Icons/CaretRight';
import Button from './Button';
import s from './PaginationControls.module.scss';

type Props = {
  onChangePage: (newPage: number) => void;
  page: number;
  numPages: number;
  className?: string;
  hideArrows?: boolean;
};

const PaginationControls: React.FC<Props> = ({ page, numPages, onChangePage, className, hideArrows }) => {
  const handleChange = (newPage: number): void => {
    if (newPage !== page) {
      onChangePage(newPage);
    }
  };

  const getDisplayPages = (): (number | string)[] => {
    const pages = [];
    if (numPages <= 7) {
      return Array.from({ length: numPages }, (_, idx) => idx + 1);
    }

    pages.push(1);

    if (page > 3) {
      pages.push('...');
    }

    for (let i = Math.max(2, page - 1); i <= Math.min(numPages - 1, page + 1); i += 1) {
      pages.push(i);
    }

    if (page < numPages - 3) {
      pages.push('...');
    }

    pages.push(numPages);

    return pages;
  };

  const pages = useMemo(getDisplayPages, [page, numPages]);

  if (numPages <= 1) {
    return null;
  }

  return (
    <div className={clsx(s.pagination, className)}>
      {!hideArrows && <Button
        disabled={page === 1}
        isCurrent={false}
        page={Math.max(1, page - 1)}
        onClick={handleChange}
      >
        <CaretLeft size={16} />
      </Button>}
      <div className={s.pages}>
        {pages.map((pageNum, idx) => (
          typeof pageNum === 'number'
            ? (
              <Button
                key={pageNum}
                disabled={false}
                isCurrent={page === pageNum}
                page={pageNum}
                onClick={handleChange}
              >
                {pageNum}
              </Button>
            )
            : (
              <span key={`ellipsis-${idx}`} className='caption'>{pageNum}</span>
            )
        ))}
      </div>
      {!hideArrows && <Button
        disabled={page >= numPages}
        isCurrent={false}
        page={Math.min(numPages, page + 1)}
        onClick={handleChange}
      >
        <CaretRight size={16} />
      </Button>}
    </div>
  );
};

export default PaginationControls;
