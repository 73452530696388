import ts from 'typescript';
import { EvaluateExpressionError } from '../errors';
import type { Evaluator } from '../types';

const evaluateExpression: Evaluator<ts.ExpressionStatement['expression']> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  if (ts.isNumericLiteral(node)) {
    return evaluators.numericLiteral({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isStringLiteral(node)) {
    return evaluators.stringLiteral({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isIdentifier(node)) {
    return evaluators.identifier({
      evaluators,
      log,
      node,
      variables,
    });
  }
  if (ts.isBinaryExpression(node)) {
    return evaluators.binaryExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isPropertyAccessExpression(node)) {
    return evaluators.propertyAccessExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isPrefixUnaryExpression(node)) {
    return evaluators.prefixUnaryExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isParenthesizedExpression(node)) {
    return evaluators.parenthesizedExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isConditionalExpression(node)) {
    return evaluators.conditionalExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (ts.isElementAccessExpression(node)) {
    return evaluators.elementAccessExpression({
      evaluators,
      log,
      node,
      variables,
    });
  }

  if (
    node
    && typeof node === 'object'
    && 'kind' in node
  ) {
    if (node.kind === ts.SyntaxKind.TrueKeyword) {
      return true;
    }
    if (node.kind === ts.SyntaxKind.FalseKeyword) {
      return false;
    }
  }

  const error = new EvaluateExpressionError('Unsupported Expression', { kind: node.kind });
  log.error(error, 'evaluate-expression-error');
  log.info({ node, variables }, 'evaluate-expression-error');

  return error;
};

export default evaluateExpression;
