import LoggedInUserGate from '@components/Auth/LoggedInUserGate';
import FormRequestDetails from './FormRequestDetails';

type Props = Omit<Parameters<typeof FormRequestDetails>[0], 'children'>;

const FormRequestWithAccessProvider: React.FC<Props> = (props) => (
  <LoggedInUserGate isAnonymousAllowed={false}>
    <FormRequestDetails {...props} />
  </LoggedInUserGate>
);

export default FormRequestWithAccessProvider;