import React from 'react';
import { FormProvider } from '@/context/FormContext';
import type { Questionnaire, QuestionnaireFieldResponse } from '@/typings/Questionnaire';
import FormContent from './FormContent';

type Props = {
  formData: Questionnaire.Questionnaire;
  completed?: boolean;
  defaultValues: Record<string, unknown>;
  isPreview?: boolean;
  onSubmit: (responses: QuestionnaireFieldResponse[], isCompleted?: boolean) => Promise<boolean>;
};

const DynamicForm: React.FC<Props> = ({ isPreview, formData, defaultValues, onSubmit, completed }) => (
  <FormProvider formData={formData} defaultValues={defaultValues ?? {}} onSubmit={onSubmit}>
    <FormContent
      isPreview={isPreview}
      formData={formData}
      completed={completed}
    />
  </FormProvider>
);

export default DynamicForm;
