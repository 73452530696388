import React, { useState } from 'react';

import type { USAddress } from '@/typings/api-models';
import Input from '../Input';
import Select from '../Select';
import Fieldset from '../shared/Fieldset';
import AddressUSAutocomplete from '../../AddressUSAutocomplete';
import s from './AddressUS.module.scss';

const states = [
  { key: 'AL', label: 'Alabama' },
  { key: 'AK', label: 'Alaska' },
  { key: 'AZ', label: 'Arizona' },
  { key: 'AR', label: 'Arkansas' },
  { key: 'CA', label: 'California' },
  { key: 'CO', label: 'Colorado' },
  { key: 'CT', label: 'Connecticut' },
  { key: 'DE', label: 'Delaware' },
  { key: 'FL', label: 'Florida' },
  { key: 'GA', label: 'Georgia' },
  { key: 'HI', label: 'Hawaii' },
  { key: 'ID', label: 'Idaho' },
  { key: 'IL', label: 'Illinois' },
  { key: 'IN', label: 'Indiana' },
  { key: 'IA', label: 'Iowa' },
  { key: 'KS', label: 'Kansas' },
  { key: 'KY', label: 'Kentucky' },
  { key: 'LA', label: 'Louisiana' },
  { key: 'ME', label: 'Maine' },
  { key: 'MD', label: 'Maryland' },
  { key: 'MA', label: 'Massachusetts' },
  { key: 'MI', label: 'Michigan' },
  { key: 'MN', label: 'Minnesota' },
  { key: 'MS', label: 'Mississippi' },
  { key: 'MO', label: 'Missouri' },
  { key: 'MT', label: 'Montana' },
  { key: 'NE', label: 'Nebraska' },
  { key: 'NV', label: 'Nevada' },
  { key: 'NH', label: 'New Hampshire' },
  { key: 'NJ', label: 'New Jersey' },
  { key: 'NM', label: 'New Mexico' },
  { key: 'NY', label: 'New York' },
  { key: 'NC', label: 'North Carolina' },
  { key: 'ND', label: 'North Dakota' },
  { key: 'OH', label: 'Ohio' },
  { key: 'OK', label: 'Oklahoma' },
  { key: 'OR', label: 'Oregon' },
  { key: 'PA', label: 'Pennsylvania' },
  { key: 'RI', label: 'Rhode Island' },
  { key: 'SC', label: 'South Carolina' },
  { key: 'SD', label: 'South Dakota' },
  { key: 'TN', label: 'Tennessee' },
  { key: 'TX', label: 'Texas' },
  { key: 'UT', label: 'Utah' },
  { key: 'VT', label: 'Vermont' },
  { key: 'VA', label: 'Virginia' },
  { key: 'WA', label: 'Washington' },
  { key: 'WV', label: 'West Virginia' },
  { key: 'WI', label: 'Wisconsin' },
  { key: 'WY', label: 'Wyoming' },
];

export const DEFAULT_VALUE: USAddress = {
  city: '',
  line1: '',
  line2: '',
  state: '',
  zip: '',
};

type AutoCompleteAddress = Partial<Omit<USAddress, 'line2'>>;

const AddressUS: React.FC<
  Pick<Parameters<typeof Fieldset>[0], 'label' | 'description' | 'required'> & {
    value: USAddress | null;
    onChange: (address: USAddress) => void;
    onBlur?: () => void;
    disabled?: boolean;
  } & {
    error?: string;
  }
> = ({ label, description, disabled, required, value, onChange, onBlur, error }) => {
  const [address, setAddress] = useState(value || DEFAULT_VALUE);

  const handleInputChange = (field: 'line2' | 'city' | 'state' | 'zip') => (v: string) => {
    const newAddress = { ...address, [field]: v };

    setAddress(newAddress);
    onChange(newAddress);
  };

  const cleanAddress = (addr: AutoCompleteAddress | null): AutoCompleteAddress => Object.fromEntries(
    Object.entries(addr || {}).filter(([_, v]) => v !== ''),
  );

  const handleAutocompleteChange = (newAddress: AutoCompleteAddress | null): void => {
    const cleanedAddress = cleanAddress(newAddress);
    const updatedAddress = {
      city: cleanedAddress.city ?? address.city,
      line1: cleanedAddress.line1 ?? address.line1,
      line2: address.line2,
      state: cleanedAddress.state ?? address.state,
      zip: cleanedAddress.zip ?? address.zip,
    };
    setAddress(updatedAddress);
    onChange(updatedAddress);
  };

  return (
    <Fieldset label={label} description={description} required={required} error={error}>
      <div className={s['address-us']}>
        <AddressUSAutocomplete
          value={address.line1}
          onAddressChange={handleAutocompleteChange}
          disabled={disabled}
        />
        <Input
          label="Street Address Line 2"
          compact
          value={address.line2}
          onChange={handleInputChange('line2')}
          placeholder="Apartment, suite, unit, building, floor, etc."
          disabled={disabled}
          onBlur={onBlur}
        />
        <div className={s.row}>
          <Input
            label="City"
            compact
            value={address.city}
            onChange={handleInputChange('city')}
            onBlur={onBlur}
            disabled={disabled}
          />
          <Select
            label="State"
            compact
            placeholder="Please choose"
            options={states}
            value={address.state}
            onChange={handleInputChange('state')}
            disabled={disabled}
          />
          <Input
            label="Zip"
            compact
            value={address.zip}
            onChange={handleInputChange('zip')}
            disabled={disabled}
            onBlur={onBlur}
          />
        </div>
      </div>
    </Fieldset>
  );
};

export default AddressUS;
