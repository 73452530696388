import ts from 'typescript';
import {
  BaseEvaluateExpressionError,
  EvaluateExpressionError,
  mergeErrors,
} from '../errors';
import type { Evaluator } from '../types';

const isNumberOrString = (val: unknown): val is number | string => (typeof val === 'number' || typeof val === 'string');

const isNumberOrUndefined = (val: unknown): val is number | undefined => (typeof val === 'number' || val === undefined);

type Logger = Parameters<Evaluator<ts.BinaryExpression>>[0]['log'];
const valueDebug = (val: unknown, log: Logger): unknown => {
  if (val instanceof BaseEvaluateExpressionError) {
    return val.type;
  }

  if (val instanceof Error) {
    log.error(val, 'evaluate-expression-unknown-value-error');
    return '__ERROR__';
  }

  if (val === undefined) {
    return '__UNDEFINED__';
  }

  return val;
};

const evaluateBinaryExpression: Evaluator<ts.BinaryExpression> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  const leftValue = evaluators.expression({
    evaluators,
    log,
    node: node.left,
    variables,
  });
  const rightValue = evaluators.expression({
    evaluators,
    log,
    node: node.right,
    variables,
  });

  let error = mergeErrors(leftValue, rightValue);

  if (!error) {
    if (node.operatorToken.kind === ts.SyntaxKind.BarBarToken) {
      return leftValue || rightValue;
    }
    if (node.operatorToken.kind === ts.SyntaxKind.AmpersandAmpersandToken) {
      return leftValue && rightValue;
    }
    if (node.operatorToken.kind === ts.SyntaxKind.EqualsEqualsEqualsToken) {
      return leftValue === rightValue;
    }
    if (node.operatorToken.kind === ts.SyntaxKind.ExclamationEqualsEqualsToken) {
      return leftValue !== rightValue;
    }
    if (node.operatorToken.kind === ts.SyntaxKind.EqualsEqualsToken) {
      return leftValue == rightValue; // eslint-disable-line eqeqeq
    }
    if (node.operatorToken.kind === ts.SyntaxKind.ExclamationEqualsToken) {
      return leftValue != rightValue; // eslint-disable-line eqeqeq
    }

    if (ts.isPlusToken(node.operatorToken)) {
      if (leftValue === undefined && rightValue === undefined) {
        return undefined;
      }
      if (typeof leftValue === 'string' || typeof rightValue === 'string') {
        return `${leftValue ?? ''}${rightValue ?? ''}`;
      }
      if (isNumberOrUndefined(leftValue) && isNumberOrUndefined(rightValue)) {
        return (leftValue ?? 0) + (rightValue ?? 0);
      }
    }

    if (ts.isMinusToken(node.operatorToken)) {
      if (leftValue === undefined && rightValue === undefined) {
        return undefined;
      }
      if (isNumberOrUndefined(leftValue) && isNumberOrUndefined(rightValue)) {
        return (leftValue ?? 0) - (rightValue ?? 0);
      }
    }

    if (ts.isAsteriskToken(node.operatorToken)) {
      if (isNumberOrUndefined(leftValue) && isNumberOrUndefined(rightValue)) {
        return (leftValue ?? 1) * (rightValue ?? 1);
      }
    }

    if (node.operatorToken.kind === ts.SyntaxKind.SlashToken) {
      if (isNumberOrUndefined(leftValue) && isNumberOrUndefined(rightValue)) {
        return (leftValue ?? 1) / (rightValue ?? 1);
      }
    }

    if (node.operatorToken.kind === ts.SyntaxKind.GreaterThanToken) {
      return (isNumberOrString(leftValue) && isNumberOrString(rightValue)) && (leftValue > rightValue);
    }
    if (node.operatorToken.kind === ts.SyntaxKind.GreaterThanEqualsToken) {
      return (isNumberOrString(leftValue) && isNumberOrString(rightValue)) && (leftValue >= rightValue);
    }
    if (node.operatorToken.kind === ts.SyntaxKind.LessThanToken) {
      return (isNumberOrString(leftValue) && isNumberOrString(rightValue)) && (leftValue < rightValue);
    }
    if (node.operatorToken.kind === ts.SyntaxKind.LessThanEqualsToken) {
      return (isNumberOrString(leftValue) && isNumberOrString(rightValue)) && (leftValue <= rightValue);
    }
  }

  if (!error) {
    error = new EvaluateExpressionError('Unsupported BinaryExpression', { kind: node.operatorToken.kind });
  }

  log.error(error, 'evaluate-expression-error');
  log.info({
    leftValue: valueDebug(leftValue, log),
    node,
    rightValue: valueDebug(rightValue, log),
    variables,
  }, 'evaluate-expression-error');

  return error;
};

export default evaluateBinaryExpression;
