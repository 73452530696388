import { Circle, RadioButton } from '@phosphor-icons/react';
import s from './RadioButtons.module.scss';
import Fieldset from '../shared/Fieldset';
import type { FieldsetProps } from '../shared/Fieldset';

type RadioButtonOption = {
  label: string;
  key: string;
};

type RadioButtonsProps = {
  name: string;
  value: string | null;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  disabled?: boolean;
  options: RadioButtonOption[];
} & FieldsetProps;

const RadioButtons: React.FC<RadioButtonsProps> = ({
  label,
  description,
  disabled,
  hint,
  error,
  name,
  onChange,
  onBlur,
  required,
  options,
  value,
}) => (
  <Fieldset
    label={label}
    description={description}
    required={required}
    hint={hint}
    error={error}
  >
    <div className={s.container}>
      {options?.map(input => (
        <label key={input.key}>
          <RadioButton size={20} weight="fill" className={`input-checked`} />
          <Circle size={20} className={`input-unchecked`} />
          <input
            type="radio"
            name={name}
            value={input.key}
            checked={input.key === value}
            onChange={onChange}
            required={required}
            disabled={disabled}
            onBlur={onBlur}
          />
          {input.label}
        </label>
      ))}
    </div>
  </Fieldset>
);

export default RadioButtons;
