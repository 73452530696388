import React, { ForwardRefRenderFunction, useState, useRef, useEffect, ChangeEventHandler } from 'react';
import s from '@/components/DesignLibrary/Atoms/Input/Input.module.scss';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { ADDRESS_COMPLETION_API_KEY } from '@/configuration/client';
import classNames from 'classnames';
import { logError } from '@/providers/ErrorTracking';
import { AnimatePresence, m } from 'framer-motion';
import AdditionalStyles from './AddressUSAutocomplete.module.scss';

type AddressUSAutocomplete = {
  line1?: string;
  city?: string;
  state?: string;
  zip?: string;
};

type InputProps = {
  value: string;
  onAddressChange: (address: AddressUSAutocomplete | null) => void;
  disabled?: boolean;
};

type CustomSelectOption = {
  title: string;
  value: string;
};

type PlaceIdItem = { id: string; item: string };

interface AddressComponent {
  longText: string;
  shortText: string;
  types: string[];
  languageCode: string;
}

const AddressUSAutocomplete: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ value, disabled, onAddressChange }) => {
  const [autocompleteOptions, setAutocompleteOptions] = useState<CustomSelectOption[]>([]);
  const [displayValue, setInputDisplayValue] = useState(value);
  const [placesId, setPlacesId] = useState<PlaceIdItem[] | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef<HTMLInputElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    if (listRef.current && selectedIndex >= 0) {
      const optionElement = listRef.current.children[selectedIndex] as HTMLElement;
      if (optionElement) {
        const listRect = listRef.current.getBoundingClientRect();
        const optionRect = optionElement.getBoundingClientRect();
        if (optionRect.bottom > listRect.bottom) {
          listRef.current.scrollTop += optionRect.bottom - listRect.bottom;
        } else if (optionRect.top < listRect.top) {
          listRef.current.scrollTop -= listRect.top - optionRect.top;
        }
      }
    }
  }, [selectedIndex]);

  const getAddressDetails = async (val: string): Promise<void> => {
    const structuredAddress = {
      city: '',
      country: '',
      line1: '',
      state: '',
      zip: '',
    };

    const place = placesId?.find((location: PlaceIdItem) => location.item === val);
    const placeId = place?.id;

    if (!placeId) {
      return;
    }

    try {
      const response = await axios.post(
        'https://places.googleapis.com/v1/places:searchText',
        {
          languageCode: 'en',
          textQuery: val,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'X-Goog-Api-Key': ADDRESS_COMPLETION_API_KEY,
            'X-Goog-FieldMask': 'places.addressComponents,places.formattedAddress',
          },
        },
      );

      if (!response.data.places || response.data.places.length === 0) {
        logError(new Error('No place details found'));
        return;
      }

      const places = response.data.places[0];
      const addressComponents = places.addressComponents || [];
      addressComponents.forEach((component: AddressComponent) => {
        const { types } = component;
        if (types.includes('street_number') || types.includes('route')) {
          structuredAddress.line1 += `${component.longText} `;
        } else if (types.includes('locality')) {
          structuredAddress.city = component.longText;
        } else if (types.includes('administrative_area_level_1')) {
          structuredAddress.state = component.shortText;
        } else if (types.includes('postal_code')) {
          structuredAddress.zip = component.longText;
        } else if (types.includes('country')) {
          structuredAddress.country = component.longText;
        }
      });

      structuredAddress.line1 = structuredAddress.line1.trim();
      setInputDisplayValue(structuredAddress.line1 || '');
      onAddressChange(structuredAddress);
    } catch (error) {
      logError(new Error('Error fetching address details: getAddressDetails'));
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = async (event): Promise<void> => {
    setInputDisplayValue(event.target.value);
    onAddressChange({ line1: event.target.value });

    if (event.target.value.length > 3) {
      try {
        const response = await axios.post(
          'https://places.googleapis.com/v1/places:autocomplete',
          {
            includedRegionCodes: ['us'],
            input: event.target.value,
            sessionToken: uuidv4(),
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'X-Goog-Api-Key': ADDRESS_COMPLETION_API_KEY,
            },
          },
        );
        setIsOpen(true);
        const places = response.data.suggestions;
        setAutocompleteOptions(
          places.map((place: { placePrediction: { text: { text: string } } }) => ({
            title: place.placePrediction.text.text,
            value: place.placePrediction.text.text,
          })) || [],
        );
        setPlacesId(
          places.map((place: { placePrediction: { placeId: string; text: { text: string } } }) => ({
            id: place.placePrediction.placeId,
            item: place.placePrediction.text.text,
          })) || [],
        );
      } catch (error) {
        logError(new Error('Error fetching autocomplete suggestions: handleChange'));
      }
    } else {
      setAutocompleteOptions([]);
    }
  };

  const handleSelectOption = (option: CustomSelectOption): void => {
    setIsOpen(false);
    setSelectedIndex(-1);
    getAddressDetails(option.value);
    inputRef.current?.focus();
  };

  const handleOptionClick = (option: CustomSelectOption): void => {
    handleSelectOption(option);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    switch (event.key) {
    case 'ArrowDown':
      event.preventDefault();
      setIsOpen(true);
      setSelectedIndex(prevIndex => (prevIndex + 1) % autocompleteOptions.length);
      break;
    case 'ArrowUp':
      event.preventDefault();
      setSelectedIndex(prevIndex => (prevIndex - 1 + autocompleteOptions.length) % autocompleteOptions.length);
      break;
    case 'Enter':
      if (isOpen && selectedIndex > -1) {
        handleSelectOption(autocompleteOptions[selectedIndex]);
        event.preventDefault();
      }
      break;
    case 'Escape':
      setIsOpen(false);
      inputRef.current?.focus();
      break;
    default:
      break;
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement | HTMLUListElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setTimeout(() => {
        setIsOpen(false);
      }, 100);
    }
  };

  return (
    <div className={classNames(s['input-field-wrapper'], AdditionalStyles['address-autocomplete-wrapper'])}>
      <label htmlFor="address-input" className={AdditionalStyles['input-label']}>
        Street Address Line 1
      </label>
      <input
        id="address-input"
        ref={inputRef}
        value={displayValue}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        className={s['input-field']}
        aria-autocomplete="list"
        aria-controls="address-suggestions"
        placeholder="Enter an address"
        autoComplete="off"
        disabled={disabled}
      />
      <AnimatePresence>
        {isOpen && autocompleteOptions.length > 0 && (
          <m.ul
            layout="size"
            ref={listRef}
            id="address-suggestions"
            role="listbox"
            className={AdditionalStyles['address-ul']}
            initial={{ opacity: 0, y: 8 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
              y: 0,
            }}
            exit={{ opacity: 0, transition: { duration: 0.1 }, y: 4 }}
          >
            {autocompleteOptions.map((option, index) => (
              <m.li
                key={index}
                layout
                tabIndex={-1}
                role="option"
                aria-selected={selectedIndex === index}
                onClick={() => handleOptionClick(option)}
                className={AdditionalStyles['address-li']}
              >
                {option.title}
              </m.li>
            ))}
          </m.ul>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AddressUSAutocomplete;
