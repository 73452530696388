import TableCell, { THProps } from '../TableCell';

type Props = Omit<THProps, 'children' | 'head'>;

const TH: React.FC<Props> = ({
  children,
  columnId,
  label,
  sort,
}) => {
  if (
    sort && columnId !== undefined
  ) {
    return (
      <TableCell
        head={true}
        label={label}
        columnId={columnId}
        sort={sort}
      >
        {children}
      </TableCell>
    );
  }

  return (
    <TableCell
      head={true}
      label={label}
    >
      {children}
    </TableCell>
  );
};

export default TH;
