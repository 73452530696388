import * as ApiModels from '@typings/api-models';
import BooleanAnswer from './BooleanAnswer';
import DateOnlyAnswer from './DateOnlyAnswer';
import DollarAnswer from './DollarAnswer';
import EmailAnswer from './EmailAnswer';
import IntegerAnswer from './IntegerAnswer';
import MultipleChoiceAnswer from './MultipleChoiceAnswer';
import NumberAnswer from './NumberAnswer';
import PercentAnswer from './PercentAnswer';
import TextAnswer from './TextAnswer';
import UnAnswered from './UnAnswered';
import UnknownAnswerType from './UnknownAnswerType';
import UrlAnswer from './UrlAnswer';
import YesNoAnswer from './YesNoAnswer';
import CurrencyAnswer from './CurrencyAnswer';
import NameAnswer from './NameAnswer';
import USAddressAnswer from './USAddressAnswer';
import IntlAddressAnswer from './IntlAddressAnswer';

type Props = {
  field: ApiModels.FormResponseAnswerField;
};

const Answer: React.FC<Props> = ({ field }) => {
  if (field.answer === null || field.answer === undefined) {
    return <UnAnswered />;
  }

  if (field.type === 'yes-no') {
    return <YesNoAnswer field={field} />;
  }

  if (
    field.type === 'short-text'
    || field.type === 'long-text'
    || field.type === 'phone-number'
    || field.type === 'numeric'
    || field.type === 'ssn'
  ) {
    return <TextAnswer field={field} />;
  }

  if (field.type === 'email') {
    return <EmailAnswer field={field} />;
  }

  if (field.type === 'date-only' || field.type === 'date') {
    return <DateOnlyAnswer field={field} />;
  }

  if (field.type === 'number') {
    return <NumberAnswer field={field} />;
  }

  if (field.type === 'integer') {
    return <IntegerAnswer field={field} />;
  }

  if (field.type === 'percent') {
    return <PercentAnswer field={field} />;
  }

  if (field.type === 'dollar') {
    return <DollarAnswer field={field} />;
  }

  if (field.type === 'boolean') {
    return <BooleanAnswer field={field} />;
  }

  if (field.type === 'url') {
    return <UrlAnswer field={field} />;
  }

  if (
    field.type === 'multiple-choice'
    || field.type === 'single-select'
    || field.type === 'multi-select'
    || field.type === 'selection'
  ) {
    return <MultipleChoiceAnswer field={field} />;
  }

  if (field.type === 'currency') {
    return <CurrencyAnswer field={field} />;
  }

  if (field.type === 'name') {
    return <NameAnswer field={field} />;
  }

  if (field.type === 'address-us') {
    return <USAddressAnswer field={field} />;
  }

  if (field.type === 'address-international') {
    return <IntlAddressAnswer field={field} />;
  }

  // as UnknownField is needed because, otherwise, we ge errors like
  //    Type '"long-text"' is not assignable to type '"unknown"'.
  // The type is not actually used in the component, so this is OK.
  return <UnknownAnswerType field={field as ApiModels.FormResponseUnknownField} />;
};

export default Answer;
