import React, { useRef, useState, useEffect, useMemo, KeyboardEvent } from 'react';
import { m } from 'framer-motion';
import classNames from 'classnames';
import CaretDown from '@/components/Icons/CaretDown';
import Check from '@/components/Icons/Check';
import s from './Select.module.scss';
import Label from '../shared/Label';

export type Option = {
  key: string;
  label: string;
};

export type SelectProps = {
  label: string;
  compact?: boolean;
  hideLabel?: boolean;
  description?: string;
  disabled?: boolean;
  value?: string | null;
  placeholder?: string;
  error?: string;
  optional?: boolean;
  hint?: string;
  options: Option[];
  onChange: (value: string) => void;
  native?: boolean;
};

const MotionCaret = m(CaretDown);

const Select: React.FC<SelectProps> = ({
  label,
  compact,
  hideLabel,
  description,
  value,
  placeholder,
  disabled,
  error,
  optional,
  hint,
  options,
  onChange,
  native,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [checkedIndex, setCheckedIndex] = useState(-1);
  const [filter, setFilter] = useState('');
  const listRef = useRef<HTMLUListElement>(null);
  const labelId = `label-${label}`;

  const filteredOptions = useMemo(
    () => options.filter(o => o.label.toLowerCase().includes(filter.toLowerCase())),
    [options, filter],
  );

  useEffect(() => {
    const optionIndex = filteredOptions.findIndex(o => o.key === value);
    setSelectedIndex(optionIndex);
    setCheckedIndex(optionIndex);
  }, [value, filteredOptions]);

  useEffect(() => {
    if (listRef.current && selectedIndex >= 0) {
      const optionElement = listRef.current.children[selectedIndex];
      const listRect = listRef.current.getBoundingClientRect();
      const optionRect = optionElement.getBoundingClientRect();
      if (optionRect.bottom > listRect.bottom) {
        listRef.current.scrollTop += optionRect.bottom - listRect.bottom;
      } else if (optionRect.top < listRect.top) {
        listRef.current.scrollTop -= listRect.top - optionRect.top;
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (isOpen && listRef.current && checkedIndex >= 0) {
      const optionElements = listRef.current.querySelectorAll('li');
      if (optionElements.length > checkedIndex) {
        const optionElement = optionElements[checkedIndex];
        const listRect = listRef.current.getBoundingClientRect();
        const optionRect = optionElement.getBoundingClientRect();
        if (optionRect.bottom > listRect.bottom) {
          listRef.current.scrollTop += optionRect.bottom - listRect.bottom;
        } else if (optionRect.top < listRect.top) {
          listRef.current.scrollTop -= listRect.top - optionRect.top;
        }
      }
    }
  }, [isOpen, checkedIndex]);

  const handleOptionSelect = (checkedValue: string): void => {
    const option = filteredOptions.find(o => o.key === checkedValue);
    if (!option) {
      return;
    }
    const optionIndex = filteredOptions.findIndex(o => o.key === checkedValue);
    setCheckedIndex(optionIndex);
    onChange(checkedValue);
    setTimeout(() => {
      setIsOpen(false);
    }, 150);
  };

  const handleToggleDropdown = (): void => {
    setIsOpen((v) => !v);
  };

  useEffect(() => {
    if (!isOpen) setSelectedIndex(-1);
    setFilter('');
  }, [isOpen]);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>): void => {
    if (!isOpen) return;

    switch (event.key) {
    case 'Escape':
      setIsOpen(false);
      break;
    case 'ArrowDown':
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        const nextIndex = (prevIndex + 1) % filteredOptions.length;
        return nextIndex;
      });
      break;
    case 'ArrowUp':
      event.preventDefault();
      setSelectedIndex(prevIndex => {
        const nextIndex = (prevIndex - 1 + filteredOptions.length) % filteredOptions.length;
        return nextIndex;
      });
      break;
    case 'Enter':
      if (selectedIndex > -1) {
        handleOptionSelect(filteredOptions[selectedIndex].key);
        event.preventDefault();
      }
      break;
    default:
      break;
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement | HTMLInputElement>): void => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsOpen(false);
    }
  };

  if (native) {
    return (
      <div className={s.wrapper}>
        {label && (
          <Label
            hideLabel={hideLabel}
            label={label}
            required={!optional}
            description={description}
            compact={compact}
            id={labelId}
          />
        )}
        <select
          id={labelId}
          value={value ?? undefined}
          onChange={e => handleOptionSelect(e.target.value)}
          className={s.nativeSelect}
          disabled={disabled}
        >
          <option value="" disabled>
            {placeholder || 'Select an option'}
          </option>
          {options?.map(option => (
            <option key={option.key} value={option.key}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }

  return (
    <div className={s.selectWrapper}>
      {(label || description) && !hideLabel && <Label
        hideLabel={hideLabel}
        label={label}
        required={!optional}
        description={description}
        compact={compact}
        id={labelId}
      />}
      <div
        tabIndex={-1}
        className={s.wrapper}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
      >
        <button
          type="button"
          className={s.button}
          onClick={handleToggleDropdown}
          aria-expanded={isOpen}
          aria-haspopup="listbox"
          aria-labelledby={labelId}
          disabled={disabled}
        >
          <div className={classNames(s.trigger, !!error && s.errorState)}>
            <span
              className={s.omitText}
              style={
                filteredOptions.find(o => o.key === value)?.key
                  ? undefined
                  : { color: 'var(--color-gray-75)' }
              }
            >
              {filteredOptions.find(o => o.key === value)?.label || placeholder || 'Select an option'}
            </span>
            <MotionCaret
              animate={{
                rotate: isOpen ? 180 : 0,
                transition: { duration: 0.2 },
              }}
              color="var(--color-gray-75)"
              size={12}
              weight="bold"
            />
          </div>
        </button>
        {isOpen && (
          <m.div
            layout="size"
            className={s.listAndFilter}
            initial={{ opacity: 0, y: 8 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.2,
              },
              y: 0,
            }}
            exit={{ opacity: 0, transition: { duration: 0.1 }, y: 4 }}
          >
            {options.length > 10 && (
              <m.input
                layout
                type="text"
                aria-label="Filter"
                placeholder="Filter..."
                onChange={e => setFilter(e.target.value)}
                onFocus={() => setIsOpen(true)}
                className={s.filter}
              />
            )}
            <m.ul
              initial={{ opacity: 1, y: 8 }}
              animate={{
                opacity: 1,
                transition: {
                  duration: 0.2,
                },
                y: 0,
              }}
              role="listbox"
              className={s.list}
              ref={listRef}
              layout
            >
              {filteredOptions?.map((option, index) => (
                <m.li
                  key={option.key + index}
                  onMouseEnter={() => setSelectedIndex(index)}
                  role="option"
                  aria-selected={selectedIndex === index}
                  aria-checked={value === option.key}
                  onClick={() => handleOptionSelect(option.key)}
                  className={s.option}
                  layout
                >
                  {option.label}
                  {value === option.key && (
                    <Check color="var(--color-primary)" size={12} weight="bold" />
                  )}
                </m.li>
              ))}
            </m.ul>
          </m.div>
        )}
      </div>
      {!hideLabel && (error || hint) && (
        <p className={error ? s.error : s.hint}>
          {error || hint}
        </p>
      )}
    </div>
  );
};

export default Select;
