import { useEffect, useMemo , useCallback, useState } from 'react';
import useNoodleApi from '@hooks/useNoodleApi';
import * as tsClient from '@tsClient';
import { SectionTemplate, QuestionnaireResponse, FieldTemplate } from '@/typings/questionnaires-api-models';
import DynamicForm from '@/components/Questionnaire/form/DynamicForm';
import { QuestionnaireFieldResponse } from '@/typings/Questionnaire';
import ProgressIndicator from '@/components/ProgressIndicator';
import Spacer from '@/components/Spacer';
import Bluebird from 'bluebird';

type Props = {
  id: string;
  onComplete: () => void;
  completed?: boolean;
};

const QuestionnaireDetails: React.FC<Props> = ({ id, onComplete, completed }) => {
  const [questionnaireFormValues, setQuestionnaireFormValues] = useState<Record<string, unknown> | null>(null);
  const [isFetchingResponses, setIsFetchingResponses] = useState(true);
  const [sectionFields, setSectionFields] = useState<Record<string, unknown>>({});
  const {
    data: questionnaireTemplate,
    getData: getQuestionnaireTemplate,
  } = useNoodleApi(tsClient.questionnaires.getQuestionnaireTemplateOfQuestionnaire);

  const {
    getData: getQuestionnaireResponses,
  } = useNoodleApi(tsClient.questionnaires.getQuestionnaireResponses);

  const {
    getData: changeQuestionnaireStatus,
  } = useNoodleApi(tsClient.questionnaires.changeQuestionnaireStatus);

  const { getData: updateQuestionnaireResponses } = useNoodleApi(tsClient.questionnaires.updateQuestionnaireResponses, {
    toastOnError: true,
  });

  const { getData: getFieldTemplates } = useNoodleApi(tsClient.questionnaires.getFieldTemplatesForSectionTemplate);

  useEffect(() => {
    getQuestionnaireTemplate({ questionnaireId: id });
  }, [getQuestionnaireTemplate, id]);

  useEffect(() => {
    const fetchResponses = async (): Promise<void> => {
      setIsFetchingResponses(true);
      setQuestionnaireFormValues(null);
      const getResponsesResponse = await getQuestionnaireResponses({
        page: 1,
        perPage: 100_000,
        questionnaireId: id,
      });

      if (getResponsesResponse.data) {
        const formValues = (getResponsesResponse?.data.items ?? []).reduce((p: Record<string, unknown>, c: QuestionnaireResponse & {
          fieldTemplate: FieldTemplate;
        }) => ({
          ...p,
          [c.fieldTemplate.key]: c.value,
        }), {});
        setQuestionnaireFormValues(formValues);
      } else {
        setQuestionnaireFormValues({});
      }
      setIsFetchingResponses(false);
    };
    fetchResponses();
  }, [getQuestionnaireResponses, id]);

  const getFieldTemplatesForSectionTemplate = useCallback(async (section: Pick<SectionTemplate, 'id' | 'slug' | 'questionnaireTemplateId'>): Promise<void> => {
    if (questionnaireTemplate?.creatorId && section.id) {
      const { data: fields } = await getFieldTemplates({
        creatorId: questionnaireTemplate.creatorId,
        questionnaireTemplateId: section.questionnaireTemplateId,
        sectionTemplateId: section.id,
      });
      if (fields) {
        setSectionFields(prev => ({
          ...prev,
          [section.id!]: fields.map((field) => ({
            ...field,
            assignedTo: field.fieldAssigneeTemplates?.map((assignee) => assignee.questionnaireAssigneeTemplateId) ?? [],
            description: field.descriptionText ?? '',
            disabled: completed,
            id: field.id!,
            validation: field.validation || undefined,
          })),
        }));
      }
    }
  }, [questionnaireTemplate?.creatorId, getFieldTemplates, completed]);

  // @ts-expect-error @todo: fix validation typing issue later
  const formData: Questionnaire.Questionnaire | undefined = useMemo(() =>
    questionnaireTemplate
      ? ({
        ...questionnaireTemplate,
        assigneeTemplates: questionnaireTemplate.questionnaireAssigneeTemplates,
        description: questionnaireTemplate.descriptionText ?? '',
        id,
        sections: questionnaireTemplate.sectionTemplates?.map((section: SectionTemplate) => ({
          ...section,
          description: section.descriptionText ?? '',
          // @todo: support html text later
          fields: sectionFields[section.id!] ?? [],
          id: section.id!,
          isLoading: !sectionFields[section.id!],
        })).sort(
          (a: SectionTemplate, b: SectionTemplate) => {
            const rankA = a.rank ?? Number.MAX_SAFE_INTEGER;
            const rankB = b.rank ?? Number.MAX_SAFE_INTEGER;
            return rankA - rankB;
          },
        ) ?? [],
        updatedByName: '',
      })
      : undefined,
  [sectionFields, questionnaireTemplate, id],
  );

  useEffect(() => {
    if (questionnaireTemplate?.sectionTemplates) {
      Bluebird.mapSeries(questionnaireTemplate.sectionTemplates.sort((a, b) => a.rank - b.rank), getFieldTemplatesForSectionTemplate);
    }
  }, [questionnaireTemplate, getFieldTemplatesForSectionTemplate]);

  const handleUpdateQuestionnaire = useCallback(async (data: QuestionnaireFieldResponse[], isCompleted?: boolean): Promise<boolean> => {
    const res = await updateQuestionnaireResponses({
      questionnaireId: id,
    }, data);

    if (isCompleted) {
      await changeQuestionnaireStatus({
        questionnaireId: id,
      },{
        status: 'submitted',
      });

      onComplete();
    }

    return !res.error;
  }, [id, onComplete, changeQuestionnaireStatus, updateQuestionnaireResponses]);

  if (!questionnaireTemplate || isFetchingResponses || !questionnaireFormValues) {
    return <><Spacer /><ProgressIndicator isCentered /></>;
  }

  return formData
    ? (
      <DynamicForm
        formData={formData}
        defaultValues={questionnaireFormValues}
        onSubmit={handleUpdateQuestionnaire}
        completed={completed}
      />
    )
    : null;
};

export default QuestionnaireDetails;
