import ts from 'typescript';
import type { EvaluateResponse, Evaluator } from '../types';

const evaluateElementAccessExpression: Evaluator<ts.ElementAccessExpression> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  // idx might actually be a number with the thing being an array.
  // to simplify just assume it's a string looking up in an object, not checking the tsc anyway...
  const objectOrArray = evaluators.expression({
    evaluators,
    log,
    node: node.expression,
    variables,
  }) as unknown as Record<string, EvaluateResponse>;

  const key = evaluators.expression({
    evaluators,
    log,
    node: node.argumentExpression,
    variables,
  }) as unknown as string;

  if (key === null || key === undefined || !objectOrArray) {
    return null;
  }

  return objectOrArray[key as string];
};

export default evaluateElementAccessExpression;
