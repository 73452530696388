import ts from 'typescript';
import type { EvaluateResponse, Evaluator } from '../types';

const evaluatePropertyAccessExpression: Evaluator<ts.PropertyAccessExpression> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  const expression = evaluators.expression({
    evaluators,
    log,
    node: node.expression,
    variables,
  }) as unknown as Record<string, EvaluateResponse>;

  return expression ? expression[node.name.text] : undefined;
};

export default evaluatePropertyAccessExpression;
