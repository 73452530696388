// @todo - DRY up
import { SlateAST, ValueOf } from '@/typings/common';

export type Scalars = {
  DateTime: Date; // Date on BE, string on FE
};

export type QuestionnaireTemplateStatus = 'draft' | 'abandoned' | 'current' | 'stale';
export type QuestionnaireStatus = 'in-progress' | 'submitted';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Condition {
  export enum Operator {
    AND = 'AND',
    OR = 'OR',
  }

  export enum Comparison {
    Equal = 'equal',
    NotEqual = 'not-equal',
    GreaterThan = 'greater-than',
    LessThan = 'less-than',
    Contain = 'contain',
    NotContain = 'not-contain',
    Filled = 'filled',
    NotFilled = 'not-filled',
  }

  export type ConditionValue = string | number | boolean | null;

  export type Condition = {
    id: string;
    dependentFieldKey: string;
    operator?: ValueOf<typeof Operator>;
    condition: ValueOf<typeof Comparison>;
    value: ConditionValue;
  };
}

export type PersonName = {
  firstName: string;
  middleName: string;
  lastName: string;
};

type Line2Type = 'apartment' | 'floor' | 'suite' | null;

type AddressCommon = {
  line1: string;
  line2: string;
  line2Type: Line2Type;
  city: string;
};

export type AddressUS = AddressCommon & {
  state: string; // @todo - literal
  zipCode: string;
};

export type AddressInternational = AddressCommon & {
  country: string;
  postCode: string;
};

export type Assignee = {
  id: string;
  name: string | null;
  primaryColour: { hex: string } | null;
  image: { url: string } | null;
};

type CustomValidators = {
  age?: {
    limit: number;
    symbol: '>=' | '>' | '=' | '<' | '<=';
    message: string;
  }
};

type NativeValidators = {
  min?: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
  type?: {
    value: 'email' | 'number';
    message: string;
  };
  pattern?: {
    value: RegExp;
    message: string;
  }
};

export type Validation = CustomValidators & NativeValidators;

export type FieldAssigneeTemplate = {
  id: string;
  questionnaireAssigneeTemplateId: string;
  fieldTemplateId: string;
};

export type ExpressionVariable = {
  id: string;
  variableName: string;
  selector: string;
  expressionId: string;
  referenceId: string;
  referenceType: 'field-template-key';
};

export type Expression = {
  expression: string;
  variables: Array<Pick<ExpressionVariable, 'variableName' | 'selector' | 'referenceId'>>;
};

export type FieldType =
  | 'short-text'
  | 'long-text'
  | 'numeric' // short text with filter?
  | 'number'
  | 'multi-select'
  | 'single-select'
  | 'selection'
  | 'percent'
  | 'currency'
  | 'date'
  | 'phone-number'
  | 'ssn'
  /* composite fields */
  | 'name'
  | 'address-us'
  | 'address-international'
  | 'list'
  /* for organizing */
  | 'break'
  | 'explanation';

export type Field = {
  descriptionAST: SlateAST | null;
  descriptionHtml: string | null;
  descriptionText: string | null;
  id: string;
  isRequired: boolean;
  key: string;
  label: string;
  options: {key: string, label: string, isDefault: boolean}[];
  rank: number;
  questionnaireId: string;
  sectionTemplateId: string;
  type: FieldType;
  validation: Validation | null; // @todo - can this by typed based on the type?
  fieldAssigneeTemplates: Array<FieldAssigneeTemplate>;
  hint: string | null;
  conditionExpression: Expression | null;
  listFieldTemplateId: string | null;
  view: string | null;
  isEditableInTableView: boolean;
  isViewableInTableView: boolean;
};

export type FieldTemplate = Omit<Field, 'questionnaireId' | 'id'> & { questionnaireTemplateId: string; id?: string };

export type QuestionnaireResponse = {
  id: string;
  index: number;
  value: unknown | null; // @todo - type based on fieldTemplate.type (does it need to be pulled up?)
  fieldTemplate: FieldTemplate;
};

export type FieldTemplateItem = Pick<FieldTemplate,
| 'descriptionHtml'
| 'descriptionText'
| 'id'
| 'isRequired'
| 'key'
| 'label'
  | 'listFieldTemplateId'
| 'options'
| 'rank'
| 'sectionTemplateId'
| 'type'
| 'questionnaireTemplateId'
| 'validation'
| 'fieldAssigneeTemplates'
| 'hint'
| 'conditionExpression'
| 'view'
  | 'isEditableInTableView'
  | 'isViewableInTableView'
>;

export type SectionTemplate = {
  id?: string;
  label: string;
  rank: number;
  slug: string;
  key: string;
  descriptionAST: SlateAST | null;
  descriptionHtml: string | null;
  descriptionText: string | null;
  questionnaireTemplateId: string;
  fieldTemplates: FieldTemplateItem[] | null;
  conditionExpression: Expression | null;
};

export type QuestionnaireAssigneeTemplate = {
  id: string;
  key: string;
  label: string;
  questionnaireTemplateId: string;
};

export type QuestionnaireTemplate = {
  id: string;
  slug: string;
  creatorId: string;
  version: number;
  versionNote: string | null;
  status: QuestionnaireTemplateStatus;
  label: string;
  descriptionHtml: string | null;
  descriptionText: string | null;
  sectionTemplates: SectionTemplate[];
  questionnaireAssigneeTemplates: QuestionnaireAssigneeTemplate[];
  updatedAt: Scalars['DateTime'];
  updatedBy: string | null;
};

export type QuestionnaireTemplateInfo = {
  id: string;
  slug: string;
  label: string;
  description: string | null;
};

export type Questionnaire = {
  id: string;
  questionnaireTemplateId: string;
  status: 'in-progress' | 'submitted';
};

export type QuestionnaireResponseWebhookData = QuestionnaireResponse & {
  field: Pick<
    Field,
    'label'
    | 'descriptionText'
    | 'id'
    | 'options'
    | 'type'
    | 'isRequired'
  >;
};

export type NoodleQuestionnaireResponseQuestionAnswer = Pick<Field, 'type'> & {
  id: string;
  question: {
    title: string;
  };
  allowMultipleSelections?: boolean;
  answer: unknown;
};

// Does not exist in questionnaires-backend, need to sync up.
export type QuestionnaireFieldInJSON = {
  type: string;
  key: string;
  label: string;
  description: string;
  optional: boolean;
  assignees: Array<{
    key: string;
    label: string;
  }>;
  rank?: number;
  hint?: string;
  view?: string;
  placeholder?: string;
  options?: {key: string; label: string, isDefault: boolean }[];
  subFields?: QuestionnaireFieldInJSON[];
  validation?: Validation | null;
  condition?: {
    expression: string;
    variables: Array<{
      variableName: string;
      selector: string;
      fieldKey: string;
    }>;
  } | null;
};

export type QuestionnaireTemplateRawJSON = {
  sections: Array<{
    key: string;
    label: string;
    description: string;
    rank?: number;
    fields: QuestionnaireFieldInJSON[];
    condition?: {
      expression: string;
      variables: Array<{
        variableName: string;
        selector: string;
        fieldKey: string;
      }>;
    } | null;
  }>
};

export type CreateQuestionnaireTemplateRequestBody = {
  label: string;
  description: string;
  jsonData?: QuestionnaireTemplateRawJSON;
};
