import TableCell, { TDProps } from '../TableCell';

type Props = TDProps;

const TD: React.FC<Props> = ({ children, ...props }) => (
  <TableCell {...props} head={false}>
    {children}
  </TableCell>
);

export default TD;