import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { useForm, FieldValues, UseFormReturn } from 'react-hook-form';
import type { Questionnaire } from '@/typings/Questionnaire';
import { QuestionnaireFieldResponse } from '@/typings/Questionnaire';

type FormContextType = {
  formMethods: UseFormReturn<FieldValues>;
  currentSection: string;
  defaultValues: Record<string, unknown>;
  setCurrentSection: (sectionId: string) => void;
  getSectionProgress: (sectionId: string, fieldsVisibility: Record<string, boolean>) => number;
  sectionErrors: { [key: string]: boolean };
  setSectionErrors: (errors: { [key: string]: boolean }) => void;
  onSubmit: (responses: QuestionnaireFieldResponse[], isCompleted?: boolean) => Promise<boolean>;
};

const FormContext = createContext<FormContextType | undefined>(undefined);

export const useFormContext = (): FormContextType => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

type FormProviderProps = {
  formData: Questionnaire.Questionnaire;
  defaultValues: Record<string, unknown>;
  children: React.ReactNode;
  onSubmit: (responses: QuestionnaireFieldResponse[], isCompleted?: boolean) => Promise<boolean>;
};

const validatePresence = (value: unknown, requiredFields: string[]): string | boolean => {
  if (typeof value === 'object' && value !== null) {
    const isValid = requiredFields.every(field => field in value && !!(value as Record<string, unknown>)[field]);
    return isValid ? true : 'Required';
  }
  return 'Required';
};

const validateName = (value: unknown): string | boolean => validatePresence(value, ['firstName', 'lastName']);

const validateCurrency = (value: unknown): string | boolean => {
  const isValid = validatePresence(value, ['amount']);
  if (isValid !== true) return isValid;
  const { amount } = value as { amount: number };
  return amount > 0 ? true : 'Required';
};

const validateAddress = (value: unknown, fieldNames: string[]): string | boolean =>
  validatePresence(value, fieldNames);

const validateUSAddress = (value: unknown): string | boolean =>
  validateAddress(value, ['line1', 'city', 'zip']);

const validateIntlAddress = (value: unknown): string | boolean =>
  validateAddress(value, ['line1', 'city', 'postCode']);

const isPresenceValid = (value: unknown, requiredFields: string[]): boolean => {
  if (typeof value === 'object' && value !== null) {
    return requiredFields.every(field => !!(value as Record<string, unknown>)[field]);
  }
  return false;
};

export const FormProvider: React.FC<FormProviderProps> = ({ formData, defaultValues, children, onSubmit }) => {
  const formMethods = useForm({
    values: defaultValues,
  });

  useEffect(() => {
    formData.sections.forEach(section => {
      section.fields.forEach(field => {
        const validationRules: Record<string, unknown> = {};

        if (field.isRequired) {
          validationRules.required = 'This field is required';
        }

        if (field.type === 'name') {
          validationRules.validate = validateName;
        } else if (field.type === 'currency') {
          validationRules.validate = validateCurrency;
        } else if (field.type === 'address-us') {
          validationRules.validate = validateUSAddress;
        } else if (field.type === 'address-international') {
          validationRules.validate = validateIntlAddress;
        }

        formMethods.register(field.key, validationRules);
      });
    });
  }, [formMethods, formData.sections]);

  const [currentSection, setCurrentSection] = useState(formData.sections.length > 0 ? formData.sections[0].id : '');
  const [sectionErrors, setSectionErrors] = useState<{ [key: string]: boolean }>({});

  const getSectionProgress = useCallback((sectionId: string, fieldsVisibility: Record<string, boolean>): number => {
    const section = formData.sections.find(_section => _section.id === sectionId);
    if (!section) return 0;

    const totalRequiredFields = section.fields
      .filter(({ key }) => fieldsVisibility[key])
      .filter(field => field.isRequired && field.type !== 'explanation')
      .length;

    let completedRequiredFields = 0;

    section.fields
      .filter(({ key }) => fieldsVisibility[key])
      .forEach(field => {
        if (field.isRequired) {
          const fieldKey = field.key;
          const fieldValue = formMethods.getValues()[fieldKey];

          let isFieldValid = false;

          if (field.type === 'name') {
            isFieldValid = isPresenceValid(fieldValue, ['firstName', 'lastName']);
          } else if (field.type === 'currency') {
            isFieldValid = isPresenceValid(fieldValue, ['amount']) && (fieldValue as { amount: number }).amount > 0;
          } else if (field.type === 'address-us') {
            isFieldValid = isPresenceValid(fieldValue, ['line1', 'city', 'zip']);
          } else if (field.type === 'address-international') {
            isFieldValid = isPresenceValid(fieldValue, ['line1', 'city', 'postCode']);
          } else {
            isFieldValid = !!fieldValue;
          }

          if (isFieldValid) {
            completedRequiredFields += 1;
          }
        }
      });

    return totalRequiredFields === 0 ? 100 : (completedRequiredFields / totalRequiredFields) * 100;
  }, [formData, formMethods]);

  const values = useMemo(() => ({
    currentSection,
    defaultValues,
    formMethods,
    getSectionProgress,
    onSubmit,
    sectionErrors,
    setCurrentSection,
    setSectionErrors,
  }), [
    currentSection,
    defaultValues,
    formMethods,
    getSectionProgress,
    onSubmit,
    sectionErrors,
    setCurrentSection,
    setSectionErrors,
  ]);

  return (
    <FormContext.Provider value={values}>
      {children}
    </FormContext.Provider>
  );
};
