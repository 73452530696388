import validateAge from './age';

type CustomValidators = {
  age?: Parameters<typeof validateAge>[0];
};

type NativeValidators = {
  min?: {
    value: number;
    message: string;
  };
  max?: {
    value: number;
    message: string;
  };
  minLength?: {
    value: number;
    message: string;
  };
  maxLength?: {
    value: number;
    message: string;
  };
  type?: {
    value: 'email' | 'number';
    message: string;
  };
  pattern?: {
    value: RegExp;
    message: string;
  };
};

export type Validation = NativeValidators & CustomValidators;

const customValidators: {
  [K in Exclude<keyof Validation, keyof NativeValidators>]: (
    props: CustomValidators[K]
  ) => string | null;
} = {
  age: (props) => props ? validateAge(props) : null,
};

export default customValidators;
