import * as ApiModels from '@typings/api-models';
import { Providers } from '@typings/common';
import AnvilEmbedFrame from '@anvilco/anvil-embed-frame';
import FormResponse from './FormResponse';
import NoFormResponse from './NoFormResponse';
import s from './FormRequestDetails.module.scss';

type AnvilEvent = {
  action: 'forgeSubmitPage' | 'forgeComplete' | 'weldComplete';
  organizationSlug: string;
  weldDataEid: string;
  forgeEid: string;
  submissionEid: string;
};

type Props = {
  onForgeComplete: () => void;
  formRequest: Pick<ApiModels.FormRequest,
    | 'status'
    | 'response'
    | 'hasEditPermission'
    | 'hasViewPermission'
    | 'url'
  >;
};

// @todo - use ref to .reload the iframe instead of stopping
const AnvilFormDetails: React.FC<Props> = ({ formRequest, onForgeComplete }) => {
  if (formRequest.hasEditPermission && formRequest.status !== ApiModels.FormStatus.COMPLETED) {
    return (
      <>
        <AnvilEmbedFrame
          iframeURL={formRequest.url}
          // Typescript thinks className isn't a valid prop
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          className={s.anvilIframe}
          onEvent={(event: AnvilEvent) => {
            if (event.action === 'forgeComplete') {
              onForgeComplete();
            }
          }}
        />
        <p className={s.anvilHelperText}>
          {/* eslint-disable-next-line max-len */}
          Please click the Green button at the bottom of each page and scroll to see if there are errors on the form and if fields were left out by you.
          You need to fill in all fields that are in red to ensure that you can submit each form.
          You should see an All Finished screen if your form submissions were entered in correctly.
          This is critical because your case is prepared with this data and this ensures that you have entered in all details for your case correctly.
        </p>
      </>
    );
  }

  if (formRequest.hasViewPermission) {
    if (formRequest.response) {
      return (
        <FormResponse formResponse={formRequest.response} provider={Providers.Anvil}/>
      );
    }

    return (
      <NoFormResponse />
    );
  }

  return (
    <NoFormResponse />
  );
};

export default AnvilFormDetails;
