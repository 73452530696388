import React, { useState, useEffect } from 'react';
import Checkbox from '../CheckBox';
import s from './Checkboxes.module.scss';
import Fieldset from '../shared/Fieldset';

export type CheckboxOption = {
  key: string;
  label: string;
};

export type CheckboxesProps = {
  options: CheckboxOption[];
  label: string;
  description?: string;
  disabled?: boolean;
  hint?: string;
  value: string[] | null;
  optional?: boolean;
  onChange: (value: string[]) => void;
  compact?: boolean;
  error?: string;
};

const Checkboxes: React.FC<CheckboxesProps> = ({
  options,
  optional,
  label,
  description,
  hint,
  value,
  onChange,
  disabled,
  compact,
  error,
}) => {
  const [checkedValues, setCheckedValues] = useState(value ?? []);

  useEffect(() => {
    setCheckedValues(value ?? []);
  }, [value]);

  const handleChange = (v: string, newIsChecked: boolean): void => {
    if (newIsChecked) {
      setCheckedValues(prev => {
        const newValue = [...prev, v];

        onChange(newValue);
        return newValue;
      });
    } else {
      setCheckedValues(prev => {
        const newValue = prev.filter((val) => val !== v);

        onChange(newValue);
        return newValue;
      });
    }
  };

  return (
    <Fieldset
      error={error}
      label={label}
      description={description}
      hint={hint}
      required={!optional}
    >
      {options?.map(option => (
        <div key={option.key} className={s.item}>
          <Checkbox
            className={s.checkbox}
            disabled={disabled}
            id={option.key}
            isChecked={!!checkedValues.includes(option.key)}
            label={option.label}
            onChange={newIsChecked => handleChange(option.key, newIsChecked)}
            size={compact ? 'sm' : 'md'}
          />
        </div>
      ))}
    </Fieldset>
  );
};

export default Checkboxes;
