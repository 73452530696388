import TextAa from '@components/Icons/TextAa';
import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field:
    | ApiModels.FormResponseTextField
    | ApiModels.FormResponseSSNField;
};

const TextAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><TextAa weight='regular' /> <BaseAnswer answer={answer} /></div>
);

export default TextAnswer;
