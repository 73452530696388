import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '@/components/Icons/Calendar';
import s from './DatePicker.module.scss';
import Label from '../shared/Label';

export interface DatePickerProps {
  label: string;
  description?: string;
  hint?: string;
  placeholder?: string;
  required?: boolean;
  optional?: boolean;
  selected?: Date;
  onChange: (date: Date) => void;
  error?: string;
  value: string | Date | null;
  disabled?: boolean;
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  label,
  description,
  disabled,
  hint,
  placeholder,
  required,
  optional,
  value,
  onChange,
  error,
}) => (
  <div className={s.dateWrapper}>
    <Label
      label={label}
      required={required && !optional}
      description={description}
      errorMessage={error}
      hint={hint}
    >
      <div className={s.wrapper}>
        <DatePicker
          showIcon
          showPopperArrow={false}
          selected={value ? new Date(value) : undefined}
          onChange={(date: Date) => onChange(date)}
          placeholderText={placeholder}
          className={s.input}
          required={required}
          icon={<Calendar className={s.icon} />}
          disabled={disabled}
        />
      </div>
    </Label>
  </div>
);

export default CustomDatePicker;
