import * as ApiModels from '@typings/api-models';

type Props = {
  field: ApiModels.FormResponseQuestionField;
};

const Question: React.FC<Props> = ({ field }) => (
  <span className='caption'>{field.question.title}</span>
);

export default Question;
