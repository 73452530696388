import { Questionnaire } from '@/typings/Questionnaire';
import * as QuestionnaireApiModels from '@/typings/questionnaires-api-models';

const validateFieldCondition = (
  comparison: QuestionnaireApiModels.Condition.Comparison,
  value: Questionnaire.ConditionValue,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldValue: any,
): boolean => {
  if (!fieldValue) {
    return (comparison === QuestionnaireApiModels.Condition.Comparison.NotFilled);
  }
  if (comparison === QuestionnaireApiModels.Condition.Comparison.Filled) {
    return true;
  }
  if (!value) {
    return false;
  }
  const str2 = value;
  const str1 = typeof fieldValue === 'object' ? Object.values(fieldValue).join(' ') : fieldValue;

  switch (comparison) {
  case QuestionnaireApiModels.Condition.Comparison.Equal:
    return str1 === str2;
  case QuestionnaireApiModels.Condition.Comparison.NotEqual:
    return str1 !== str2;
  case QuestionnaireApiModels.Condition.Comparison.GreaterThan:
    return str1 > str2;
  case QuestionnaireApiModels.Condition.Comparison.LessThan:
    return str1 < str2;
  case QuestionnaireApiModels.Condition.Comparison.Contain:
    return str1.toString().includes(str2);
  case QuestionnaireApiModels.Condition.Comparison.NotContain:
    return !(str1.toString().includes(str2));
  default:
    return false;
  }
};

export default validateFieldCondition;
