type Props = {
  className?: string;
};

const TBody: React.FC<Props> = ({ children, className }) => (
  <tbody className={className}>
    {children}
  </tbody>
);

export default TBody;