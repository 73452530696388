import ToggleRight from "@components/Icons/ToggleRight";
import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseBooleanField;
};

const BooleanAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}><ToggleRight weight='regular' /><BaseAnswer answer={answer ? 'true' : 'false'} /></div>
);

export default BooleanAnswer;
