const validate = ({
  value,
  countryCode,
  isRequired,
}: {
  value: string;
  countryCode: string | undefined;
  isRequired: boolean;
}): string | null => {
  if (value && !countryCode) {
    return 'Please select a country.';
  }

  if (isRequired && (!value || !countryCode || value === `+${countryCode}`)) {
    return 'Required.';
  }

  return null;
};

export default validate;
