import * as ApiModels from '@typings/api-models';
import QuestionnaireDetails from '@/components/Questionnaire/QuestionnaireDetails';
import NoFormResponse from './NoFormResponse';

type Props = {
  onComplete: () => void;
  formRequest: Pick<ApiModels.FormRequest,
    | 'status'
    | 'response'
    | 'hasEditPermission'
    | 'hasViewPermission'
    | 'url'
    | 'formReferenceId'
  > & {
    template: Pick<ApiModels.FormRequestTemplate, 'id' | 'name' | 'provider' | 'providerLocator'>;
  };
};

const NoodleQuestionnaireFormDetails: React.FC<Props> = ({ formRequest, onComplete }) => {
  if (formRequest.hasEditPermission && formRequest.status !== ApiModels.FormStatus.COMPLETED) {
    return (
      <QuestionnaireDetails id={formRequest.formReferenceId} onComplete={onComplete} />
    );
  }

  if (formRequest.hasViewPermission) {
    if (formRequest.response) {
      return (
        <QuestionnaireDetails id={formRequest.formReferenceId} onComplete={onComplete} completed />
      );
    }

    return (
      <NoFormResponse />
    );
  }

  return (
    <NoFormResponse />
  );
};

export default NoodleQuestionnaireFormDetails;
