import Plus from '@components/Icons/Plus';
import classNames from 'classnames';
import s from './TableRow.module.scss';

type Props = {
    onClick: () => void;
};

const AddButton: React.FC<Props> = ({ onClick }) => (
  <button
    className={classNames(s.addButton)}
    onClick={onClick}
  >
    <Plus size='10' weight='bold' stroke='var(--color-gray-0)' stroke-width='20' fill = 'var(--color-gray-0)'>
    </Plus>
  </button>
);

export default AddButton;