const getFieldValue = (value: unknown): unknown => {
  if (!value) {
    return value as string | number | null | undefined;
  }
  // multi select field (checkbox, always has one field)
  if (Array.isArray(value)) {
    return value[0];
  }
  return value;
};

export default getFieldValue;
