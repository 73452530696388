import React from 'react';

import type { Name as NameType } from '@/typings/api-models';
import Input from '../Input';
import s from './Name.module.scss';
import Fieldset from '../shared/Fieldset';

export type NameProps = {
  label: string;
  description?: string;
  disabled?: boolean;
  value: NameType | null;
  placeholder?: string;
  error?: string | {
    firstName?: string;
    lastName?: string;
  };
  optional?: boolean;
  hint?: string;
  onChange: (value: NameType) => void;
  onBlur?: () => void;
};

export const DEFAULT_VALUE: NameType = {
  firstName: '',
  lastName: '',
  middleName: '',
};

const Name: React.FC<NameProps> = ({
  label,
  description,
  disabled,
  value,
  placeholder,
  error,
  optional,
  hint,
  onChange,
  onBlur,
}) => {
  const handleInputChange = (field: keyof NameType) => (thisValue: string) => {
    onChange({
      ...DEFAULT_VALUE,
      ...(value || {}),
      [field]: thisValue,
    });
  };

  return (
    <Fieldset
      label={label}
      description={description}
      required={!optional}
      hint={hint}
      error={typeof error === 'string' ? error : undefined}
    >
      <div className={s['name-inputs']}>
        <Input
          label="First Name"
          compact
          value={value?.firstName ?? DEFAULT_VALUE.firstName}
          placeholder={placeholder}
          optional={optional}
          onChange={handleInputChange('firstName')}
          disabled={disabled}
          onBlur={onBlur}
          error={typeof error === 'object' ? error.firstName : undefined}
        />
        <Input
          optional
          label="Middle Name"
          compact
          value={value?.middleName ?? DEFAULT_VALUE.middleName}
          placeholder={placeholder}
          onChange={handleInputChange('middleName')}
          disabled={disabled}
          onBlur={onBlur}
        />
        <Input
          label="Last Name"
          compact
          value={value?.lastName ?? DEFAULT_VALUE.lastName}
          placeholder={placeholder}
          optional={optional}
          onChange={handleInputChange('lastName')}
          disabled={disabled}
          onBlur={onBlur}
          error={typeof error === 'object' ? error.lastName : undefined}
        />
      </div>
    </Fieldset>
  );
};

export default Name;
