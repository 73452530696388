import { useMemo } from 'react';
import { logError } from '@providers/ErrorTracking';
import UnAnswered from './UnAnswered';

type Props = {
  answer: string | null;
};

const BaseAnswer: React.FC<Props> = ({ answer }) => {
  const definitelyAStringOrNull = useMemo<string | null>(() => {
    if (answer === null || typeof answer === 'string') {
      return answer;
    }
    logError('Trying to display a non-string answer', { answer });
    return JSON.stringify(answer);
  }, [answer]);

  if (answer === null || answer === undefined) {
    return <UnAnswered />;
  }

  return (
    <p className='body-sm'>
      {definitelyAStringOrNull}
    </p>
  );
};

export default BaseAnswer;
