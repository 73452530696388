import { createContext } from 'react';

type UserProfileContextState = {
  creatorSlug: string;
  creatorId: string | null;
  setBrowserTabTitle: (title: string | null) => void;
  isInUserProfile: boolean;
};

const UserProfileContext = createContext<UserProfileContextState>({
  creatorId: null,
  creatorSlug: '',
  isInUserProfile: false,
  setBrowserTabTitle: () => { /* empty */ },
});

export default UserProfileContext;
