import { nanoid } from 'nanoid';
import * as ApiModels from '@typings/api-models';

const typeformResponseToFields = (
  response: ApiModels.FormRequestResponseTypeform,
): Array<ApiModels.FormResponseField> => (response.definition?.fields || [])
  .map(typeformQuestion => {
    const question = {
      title: typeformQuestion.title || typeformQuestion.id || '',
    };

    const questionId = typeformQuestion.id || nanoid();
    const typeformAnswer = (response.answers || []).find(
      answer => answer?.field?.id && typeformQuestion?.id && answer.field.id === typeformQuestion.id,
    )
      || null;

    if (typeformQuestion.type === 'long_text') {
      return {
        answer: typeformAnswer?.text ?? null,
        id: questionId,
        question,
        type: 'long-text',
      };
    }

    if (typeformQuestion.type === 'short_text') {
      return {
        answer: typeformAnswer?.text ?? null,
        id: questionId,
        question,
        type: 'short-text',
      };
    }

    if (typeformQuestion.type === 'date') {
      return {
        answer: typeformAnswer?.date ?? null,
        id: questionId,
        question,
        type: 'date-only',
      };
    }

    if (typeformQuestion.type === 'number') {
      return {
        answer: typeformAnswer?.number ?? null,
        id: questionId,
        question,
        type: 'number',
      };
    }

    if (typeformQuestion.type === 'rating' || typeformQuestion.type === 'opinion_scale') {
      return {
        answer: typeformAnswer?.number ?? null,
        id: questionId,
        question,
        type: 'integer',
      };
    }

    if (typeformQuestion.type === 'boolean' || typeformQuestion.type === 'legal') {
      return {
        answer: typeformAnswer?.boolean ?? null,
        id: questionId,
        question,
        type: 'boolean',
      };
    }

    if (typeformQuestion.type === 'yes_no') {
      return {
        answer: typeformAnswer?.boolean ?? null,
        id: questionId,
        question,
        type: 'yes-no',
      };
    }

    if (typeformQuestion.type === 'email') {
      return {
        answer: typeformAnswer?.email ?? null,
        id: questionId,
        question,
        type: 'email',
      };
    }

    if (typeformQuestion.type === 'url' || typeformQuestion.type === 'calendly') {
      return {
        answer: typeformAnswer?.url ?? null,
        id: questionId,
        question,
        type: 'url',
      };
    }

    if (typeformQuestion.type === 'picture_choice' || typeformQuestion.type === 'multiple_choice') {
      const allowMultipleSelections = typeformQuestion.allow_multiple_selections || false;
      if (allowMultipleSelections) {
        return {
          allowMultipleSelections,
          answer: typeformAnswer
            ? typeformAnswer?.choices?.labels ?? []
            : null,
          id: questionId,
          question,
          type: 'multiple-choice',
        };
      }
      return {
        allowMultipleSelections,
        answer: typeformAnswer?.choice?.label ?? null,
        id: questionId,
        question,
        type: 'multiple-choice',
      };
    }

    if (typeformAnswer) {
      const { field, type, ...possibleAnswers } = typeformAnswer;
      return {
        answer: possibleAnswers,
        id: questionId,
        question,
        type: 'unknown',
      };
    }

    return {
      answer: null,
      id: questionId,
      question,
      type: 'unknown',
    };
  });

export default typeformResponseToFields;
