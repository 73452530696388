import * as React from "react";
import classNames from "classnames";
import MagnifyingGlass from "@components/Icons/MagnifyingGlass";
import s from "./SearchInput.module.scss";

const SearchInput: React.FC<{
  label: string;
  autoComplete?: string;
  placeholder?: string;
  setSearch: (search: string) => void;
  className?: string;
  autoFocus?: boolean;
  hideLabel?: boolean;
}> = ({ label, autoComplete, placeholder, setSearch, className, autoFocus, hideLabel }) => {
  const [innerSearch, setInnerSearch] = React.useState('');
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setSearch(innerSearch);
    }, 300);
    return () => {
      clearTimeout(handler);
    };
  }, [innerSearch, setSearch]);
  return (
    <label className={classNames(s.search, hideLabel && s.searchWithIcon, className)}>
      {hideLabel ? <MagnifyingGlass size={16} /> : label}
      <input autoFocus={autoFocus} name="search" type="search" placeholder={placeholder} autoComplete={autoComplete ? 'on' : 'off'} onChange={e => setInnerSearch(e.target.value)} />
    </label>
  );
};

export default SearchInput;
