import * as ApiModels from '@typings/api-models';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseUnknownField;
};

// Use a pre tag so that new lines are maintained.
const UnknownAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}>
    <pre className='body-sm'>
      {JSON.stringify(answer, null, 2)}
    </pre>
  </div>
);

export default UnknownAnswer;
