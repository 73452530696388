import classNames from 'classnames';
import { useState, useEffect } from 'react';

import type { CurrencyValue, CurrencyType } from '@/typings/api-models';
import Select from '../Select';
import Input, { InputProps } from '../Input';
import s from './Currency.module.scss';
import Label from '../shared/Label';

const CURRENCY_OPTIONS = [
  { key: 'USD', label: 'USD' },
  { key: 'CAD', label: 'CAD' },
  { key: 'EUR', label: 'EUR' },
  { key: 'GBP', label: 'GBP' },
];

export type CurrencyProps = Omit<InputProps, 'type' | 'prefix' | 'value' | 'onChange'> & {
  value: CurrencyValue | null;
  onChange: (value: CurrencyValue) => void;
  nativeSelect?: boolean;
  disabled?: boolean;
};

export const DEFAULT_VALUE: CurrencyValue = {
  amount: 0,
  currency: 'USD',
};

const Currency: React.FC<CurrencyProps> = ({
  onChange,
  label,
  optional,
  description,
  disabled,
  error,
  hint,
  value,
  nativeSelect,
  ...props
}) => {
  const [currency, setCurrency] = useState(value?.currency ?? DEFAULT_VALUE.currency);
  const [amount, setAmount] = useState(value?.amount ?? DEFAULT_VALUE.amount);

  useEffect(() => {
    onChange({
      amount,
      currency,
    });
  }, [currency, amount, onChange]);

  return (
    <div className={s.wrapper}>
      <Label
        label={label || ''}
        required={!optional}
        description={description}
        errorMessage={error}
        hint={hint}
      >
        <div className={classNames(s['input-component'], error && s.error)}>
          <Select
            value={currency}
            label="Currency"
            options={CURRENCY_OPTIONS}
            onChange={(v) => setCurrency(v as CurrencyType)}
            hideLabel
            native={nativeSelect}
            placeholder=''
            disabled={disabled}
          />
          <Input disabled={disabled} type="number" min={0} {...props} value={`${amount}`} onChange={(text) => setAmount(+text)} />
        </div>
      </Label>
    </div>
  );
};

export default Currency;
