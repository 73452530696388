import { nanoid } from 'nanoid';
import * as ApiModels from '@typings/api-models';
import removeNullish from '@helpers/removeNullish';

const formatFullName = (value: ApiModels.AnvilFullNameValue | undefined): string | null =>
  value ? [value.firstName, value.mi, value.lastName].filter(removeNullish).join(' ') : null;

const formatFullAddress = (value: ApiModels.AnvilAddressValue | undefined): string | null =>
  value ? [value.street1, value.city, value.state, value.country.toLocaleUpperCase(), value.zip].filter(removeNullish).join(' ') : null;

function convertQuestionType(questionType: string): string {
  switch (questionType) {
  case 'longText':
    return 'long-text';
  case 'shortText':
  case 'numericText':
  case 'phone':
  case 'fullName':
  case 'compoundSelect':
  case 'radioButtons':
    return 'short-text';
  case 'date':
  case 'dateSelect':
    return 'date-only';
  case 'integer':
  case 'number':
  case 'ssn':
  case 'ein':
    return 'number';
  case 'email':
    return 'email';
  case 'percent':
    return 'percent';
  case 'dollar':
    return 'dollar';
  case 'checkbox':
    return 'yes-no';
  case 'usAddress':
  case 'intlAddress':
    return 'long-text';
  default:
    return 'unknown';
  }
}

const anvilResponseToFields = (response: ApiModels.FormRequestResponseAnvil): Array<ApiModels.FormResponseField> => {
  const allFormResponseFields: Array<ApiModels.FormResponseField> = [];
  (response.pages || []).forEach(anvilPage => {
    const formResponseFields = anvilPage.fields.map(anvilQuestion => {
      const question = {
        title: anvilQuestion.label || anvilQuestion.options?.boxLabel || anvilQuestion.id || '',
      };
      const arrayFieldId = anvilQuestion.fields && anvilQuestion.fields.length > 0 ? anvilQuestion.fields[0].id : null;
      const questionId = anvilQuestion.id || nanoid();
      const anvilAnswer = response.submission.payload[questionId] as typeof response.submission.payload[string] | undefined;

      if (
        anvilQuestion.type === 'ssn'
        || anvilQuestion.type === 'ein'
        || anvilQuestion.type === 'checkbox'
        || anvilQuestion.type === 'dollar'
        || anvilQuestion.type === 'percent'
        || anvilQuestion.type === 'number'
        || anvilQuestion.type === 'email'
        || anvilQuestion.type === 'integer'
        || anvilQuestion.type === 'shortText'
        || anvilQuestion.type === 'numericText'
        || anvilQuestion.type === 'longText'
        || anvilQuestion.type === 'date'
        || anvilQuestion.type === 'dateSelect'
      ) {
        return {
          answer: anvilAnswer?.value ?? null,
          id: questionId,
          question,
          type: convertQuestionType(anvilQuestion.type),
        } as ApiModels.FormResponseField;
      }

      if (anvilQuestion.type === 'usAddress' || anvilQuestion.type === 'intlAddress') {
        const addressAnswer = anvilAnswer?.value as ApiModels.AnvilAddressValue | undefined;
        return {
          answer: formatFullAddress(addressAnswer),
          id: questionId,
          question,
          type: convertQuestionType(anvilQuestion.type),
        } as ApiModels.FormResponseField;
      }

      if (anvilQuestion.type === 'compoundSelect' || anvilQuestion.type === 'radioButtons') {
        const compoundSelectAnswer = anvilAnswer?.value as ApiModels.AnvilCompoundSelectValue | undefined;
        return {
          answer: compoundSelectAnswer?.v ?? null,
          id: questionId,
          question,
          type: convertQuestionType(anvilQuestion.type),
        } as ApiModels.FormResponseField;
      }

      if (anvilQuestion.type === 'phone') {
        const phoneAnswer = anvilAnswer?.value as ApiModels.AnvilPhoneValue | undefined;
        return {
          answer: phoneAnswer?.num ?? null,
          id: questionId,
          question,
          type: convertQuestionType(anvilQuestion.type),
        } as ApiModels.FormResponseField;
      }

      if (anvilQuestion.type === 'fullName') {
        const fullNameAnswer = anvilAnswer?.value as ApiModels.AnvilFullNameValue | undefined;
        return {
          answer: formatFullName(fullNameAnswer),
          id: questionId,
          question,
          type: convertQuestionType(anvilQuestion.type),
        } as ApiModels.FormResponseField;
      }

      if (anvilQuestion.type === 'array' && arrayFieldId) {
        const answer = (anvilAnswer?.value as ApiModels.AnvilArrayValue | undefined)?.map(arrayAnswer => anvilQuestion.fields?.map(f => {
          let answerValue: unknown;
          if (!arrayAnswer || !arrayAnswer[f.id]) {
            answerValue = null;
          } else if (arrayAnswer[f.id].type === 'usAddress' || arrayAnswer[f.id].type === 'intlAddress') {
            answerValue = formatFullAddress(arrayAnswer[f.id].value as ApiModels.AnvilAddressValue);
          } else if (arrayAnswer[f.id].type === 'compoundSelect' || arrayAnswer[f.id].type === 'radioButtons') {
            answerValue = (arrayAnswer[f.id].value as ApiModels.AnvilCompoundSelectValue).v;
          } else if (arrayAnswer[f.id].type === 'phone') {
            answerValue = (arrayAnswer[f.id].value as ApiModels.AnvilPhoneValue).num;
          } else if (arrayAnswer[f.id].type === 'fullName') {
            answerValue = formatFullName(arrayAnswer[f.id].value as ApiModels.AnvilFullNameValue);
          } else {
            answerValue = arrayAnswer?.[f.id]?.value;
          }

          return {
            answer: answerValue ?? null,
            id: f.id,
            question: { title: f.label },
            type: (arrayAnswer && arrayAnswer[f.id]) ? convertQuestionType(arrayAnswer[f.id].type) : 'unknown',
          };
        }));

        return {
          answer,
          id: questionId,
          question,
          type: 'array',
        } as ApiModels.FormResponseField;
      }

      if (anvilAnswer) {
        console.info('unhandled question', { anvilAnswer, anvilQuestion });
        return {
          answer: anvilAnswer?.value ?? null,
          id: questionId,
          question,
          type: 'unknown',
        } as ApiModels.FormResponseField;
      }

      return {
        answer: null,
        id: questionId,
        question,
        type: 'unknown',
      } as ApiModels.FormResponseField;
    });
    allFormResponseFields.push(...formResponseFields);
  });

  return allFormResponseFields;
};

export default anvilResponseToFields;
