import { TableRow, TD } from '@/components/DesignLibrary/Table';
import * as ApiModels from '@typings/api-models';
import Question from './Question';
import Answer from './Answer';
import s from './QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseAnswerField & ApiModels.FormResponseQuestionField;
};

const QuestionAndAnswer: React.FC<Props> = ({ field }) => (
  <TableRow>
    <TD><Question field={field} /></TD>
    <TD>
      {field.type === 'array'
        ? (
          <>
            {field.answer?.map((a,index) =>

              (
                <div key={index}>
                  <h4 className={s.arrayAnswerHeader}>Answer {index + 1}</h4>
                  {
                    a.map(f => (
                      <div key={f.id} className={s.arrayItem}>
                        <Question
                          field={{
                            question: f.question,
                          }}
                        />
                        <Answer field={f} />
                      </div>
                    ))
                  }
                  <br/>
                </div>
              ),

            )}

          </>
        )
        : (
          <Answer field={field} />
        )}
    </TD>
  </TableRow>
);

export default QuestionAndAnswer;
