import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseNameField;
};

const NameAnswer: React.FC<Props> = ({ field: { answer } }) => (
  <div className={s.answer}>{answer === null
    ? null
    : (
      <>
        <BaseAnswer answer={answer.firstName} />
        <BaseAnswer answer={!answer.middleName ? null : answer.middleName} />
        <BaseAnswer answer={answer.lastName} />
      </>
    )}</div>
);

export default NameAnswer;
