import { Controller } from 'react-hook-form';
import type { Questionnaire, FieldType } from '@/typings/Questionnaire';
import {
  AddressIntl,
  AddressUS,
  Checkboxes,
  Currency,
  DatePicker,
  Input,
  TextArea,
  PhoneInput,
  Percent,
  RadioButtons,
  Select,
  Name,
} from '@/components/DesignLibrary/Atoms';
import { useEffect, useMemo, useState } from 'react';
import getFieldValue from '@helpers/questionnaires/getFieldValue';
import evaluateExpression from '@helpers/evaluateExpression';
import { useFormContext } from '@context/FormContext';
import { getValidationRules } from '@helpers/validation/schema';
import Label from '@/components/DesignLibrary/Atoms/shared/Label';
import List from '../List';

type FormFieldProps = {
  field: Questionnaire.FieldCommon;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any;
  isPreview?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const componentsMap: { [key in FieldType]: React.FC<any> } = {
  'address-international': AddressIntl,
  'address-us': AddressUS,
  // break: () => null,
  currency: Currency,
  date: DatePicker,
  explanation: ({ label, description }) => <Label label={label} description={description} required />,
  list: List,
  'long-text': TextArea,
  'multi-select': Checkboxes,
  name: Name,
  number: Input,
  numeric: Input,
  percent: Percent,
  'phone-number': PhoneInput,
  selection: Select,
  'short-text': Input,
  'single-select': RadioButtons,
  'ssn': Input,
};

const FormField: React.FC<FormFieldProps> = ({ field, control, rules, isPreview = false }) => {
  const [isVisible, setIsVisible] = useState(isPreview);
  const [isEvaluated, setIsEvaluated] = useState(false);
  const { defaultValues, formMethods } = useFormContext();

  const variables = formMethods.watch(field.conditionExpression?.variables?.map(v => v.referenceId) || []);

  useEffect(() => {
    const { conditionExpression } = field;

    if (conditionExpression && !isPreview) {
      const variablesWithValues = conditionExpression.variables?.map((variable, idx) => ({
        ...variable,
        value: getFieldValue(variables[idx]),
      })) || [];
      const result = evaluateExpression({
        expression: conditionExpression.expression,
        variables: variablesWithValues.reduce((acc, val) => ({
          ...acc,
          [val.variableName]: val.value,
        }), {}),
      });
      setIsVisible(Boolean(result));
    } else {
      setIsVisible(true);
    }
    setIsEvaluated(true);
  }, [field, variables, isPreview]);

  useEffect(() => {
    if (!isVisible && isEvaluated) {
      formMethods.unregister(field.key);
    }
  }, [isVisible, isEvaluated, field.key, formMethods]);

  const defaultValueOption = useMemo(() => field.options && Array.isArray(field.options)
    ? field.options.find(option => option.isDefault === true)
    : undefined, [field.options]);

  useEffect(() => {
    if (
      (field.type === 'selection' || field.type === 'single-select')
      && defaultValueOption
      && !formMethods.getValues(field.key)
    ) {
      formMethods.setValue(field.key, defaultValueOption.key);
    }
  }, [field.type, field.key, defaultValueOption, formMethods]);

  const Component = componentsMap[field.type];

  if (!Component || !isVisible) return null;

  formMethods.register(field.key, getValidationRules(field));

  const { key, ...restProps } = field;

  return (
    <Controller
      name={field.key}
      control={control}
      shouldUnregister={false}
      defaultValue={
        (field.type === 'selection' || field.type === 'single-select')
          ? defaultValueOption?.key
          : defaultValues[field.key]
      }
      rules={rules}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <>
          <Component
            key={key}
            {...restProps}
            isPreview={isPreview}
            field={field}
            required={field.isRequired}
            optional={!field.isRequired}
            disabled={field.disabled}
            value={value}
            onChange={onChange}
            onBlur={async () => {
              onBlur();
              if (error?.message) {
                await formMethods.trigger();
              }
            }}
            ref={field.type === 'list' || field.type === 'single-select' ? null : ref}
            control={control}
            rules={rules}
            error={error?.message}
          />
        </>
      )}
    />
  );
};

export default FormField;