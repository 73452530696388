import React, { useEffect, useState } from 'react';
import CircularProgress from '@/components/CircularProgress';
import WarningCircle from '@/components/Icons/WarningCircle';
import CheckCircle from '@/components/Icons/CheckCircle';
import List from '@/components/Icons/List';
import ArrowLineUp from '@/components/Icons/ArrowLineUp';
import Buttons from '@/components/Buttons';
import { useIsMobile } from '@/hooks';
import { SIZES } from '@/styles/media';
import classNames from 'classnames';
import ProgressIndicator from '@components/ProgressIndicator';

import s from './SectionNavigation.module.scss';

type SectionNavigationProps = {
  sections: { id: string; label: string; progress: number; isLoading: boolean; hasError?: boolean }[];
  currentSection: string;
  onSelectSection: (sectionId: string) => void;
  completed?: boolean;
};

const SectionNavigation: React.FC<SectionNavigationProps> = ({ sections, currentSection, onSelectSection, completed }) => {
  const isMobile = useIsMobile(SIZES.md);
  const [open, setOpen] = useState(true);
  const currentSectionData = sections.find(section => section.id === currentSection);
  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  return (
    <nav className={classNames(s.nav, isMobile && open && s.navOpen)}>
      <Buttons disabled={!isMobile} isWrapper onClick={() => setOpen(!open)} className={classNames(s.header, isMobile ? 'caption' : 'body-sm')}>
        {!isMobile && 'Sections'}
        {isMobile && currentSectionData && (
          <>
            {open
              ? <><ArrowLineUp size={16} />Hide Sections</>
              : <><List size={16} />
                {currentSectionData.isLoading
                  ? (
                    <ProgressIndicator size={14} />
                  )
                  : (
                    <>
                      {currentSectionData.progress === 100
                        ? (
                          <CheckCircle weight="fill" color="var(--color-success)" size={14} />
                        )
                        : (
                          <CircularProgress size={14} progress={currentSectionData.progress} />
                        )}
                    </>
                  )}
                <b>{currentSectionData.label}</b></>}
          </>
        )}
      </Buttons>
      {open && (
        <ul>
          {sections.map(section => (
            <li key={section.id}>
              <Buttons
                isWrapper
                className={`${section.id === currentSection ? s.active : ''} ${section.hasError ? s.error : ''}`}
                onClick={() => onSelectSection(section.id)}
              >
                {section.label}
                {!completed && <>
                  {section.hasError
                    ? (
                      <WarningCircle className={s['error-indicator']} size={16} />
                    )
                    : (
                      <>
                        {section.isLoading
                          ? (
                            <ProgressIndicator size={14} />
                          )
                          : (
                            <>
                              {section.progress === 100
                                ? (
                                  <CheckCircle weight="fill" color="var(--color-success)" size={14} />
                                )
                                : (
                                  <CircularProgress size={14} progress={section.progress} />
                                )}
                            </>
                          )}
                      </>
                    )}
                </>}
              </Buttons>
            </li>
          ))}
        </ul>
      )}
    </nav>
  );
};

export default SectionNavigation;
