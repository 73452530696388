import ts from 'typescript';
import { EvaluateExpressionError } from '../errors';
import type { Evaluator } from '../types';

const evaluateConditionalExpression: Evaluator<ts.ConditionalExpression> = ({
  node,
  variables,
  log,
  evaluators,
}) => {
  const conditionValue = evaluators.expression({
    evaluators,
    log,
    node: node.condition,
    variables,
  });

  if (conditionValue instanceof EvaluateExpressionError) {
    log.error(conditionValue, 'evaluate-conditional-expression-condition-error');
    log.info({ node, variables }, 'evaluate-conditional-expression-condition-error');
    return false;
  }

  let value;

  if (!(conditionValue instanceof Error)) {
    if (conditionValue) {
      value = evaluators.expression({
        evaluators,
        log,
        node: node.whenTrue,
        variables,
      });
    } else {
      value = evaluators.expression({
        evaluators,
        log,
        node: node.whenFalse,
        variables,
      });
    }
  }

  if (value instanceof Error) {
    log.error(value, 'evaluate-conditional-expression-value-error');
    log.info({ node, variables }, 'evaluate-conditional-expression-value-error');
    return false;
  }

  return value;
};

export default evaluateConditionalExpression;
