import classNames from 'classnames';
import s from './PaginationControls.module.scss';

type Props = {
  disabled: boolean
  isCurrent: boolean;
  page: number;
  onClick: (newPage: number) => void;
};

const Button: React.FC<Props> = ({ disabled, isCurrent, page, onClick, children }) => (
  <button
    className={classNames(s.button, isCurrent && s.selected)}
    disabled={disabled}
    onClick={() => onClick(page)}
  >
    {children}
  </button>
);
export default Button;