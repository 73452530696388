import Calendar from '@components/Icons/Calendar';
import * as ApiModels from '@typings/api-models';
import BaseAnswer from './BaseAnswer';
import s from '../QuestionAndAnswer.module.scss';

type Props = {
  field: ApiModels.FormResponseDateOnlyField;
};

const DateOnlyAnswer: React.FC<Props> = ({ field: { answer } }) => {
  const answerDate = answer ? new Date(answer) : null;
  return (
    <div className={s.answer}>
      <Calendar weight='regular' />
      <BaseAnswer
        answer={answerDate ? answerDate.toLocaleDateString('default', { day: 'numeric', month: 'short', timeZone: 'UTC', year: 'numeric' }) : ''}
      />
    </div>
  );
};

export default DateOnlyAnswer;
